<script>
import { Combobox, ComboboxInput, ComboboxButton, ComboboxLabel, ComboboxOption, ComboboxOptions } from '@headlessui/vue'
import {CheckIcon, SelectorIcon} from '@heroicons/vue/solid'

export default {
    components:{
        Combobox, ComboboxInput, ComboboxButton, ComboboxLabel, ComboboxOption, ComboboxOptions, CheckIcon, SelectorIcon
    },
    props: {
        modelValue: [String, Object, Number],
        name: String,
        error: String,
        readonly: Boolean,
        options: {
            type : Array,
            // eslint-disable-next-line vue/require-valid-default-prop
            default : [
                { text : null, value: null, explanation:null }
            ],
            minSize : {
                type : String,
                default : null,
            },
        },
        placeholder : {
            type : String,
            default : "Select an Option"
        },
        useDefaultMsg : {
            type : Boolean,
            default : false
        },
        minWFit : Boolean,
        full: Boolean,
        hFull : Boolean,
        width14: Boolean,
        width20: Boolean,
        width24: Boolean,
        width36 : Boolean,
        width40: Boolean,
        width52 : Boolean,
        width60: Boolean,
        width72: Boolean,
        height11: Boolean,
        height12 : Boolean,
        defaultRed : Boolean,
        required : Boolean,
    },
    emits: ["change", "update:modelValue"],
    data() {
        return {
            showOptions: false,
            selectText: null,
            inputValue : null,
            selected : null,
        };
    },
    computed:{
        displayInputValue() {
            return (item) => {
                let value = '';
                if (item != null) {
                    value = item.text;
                }
                return value;
            };
        },
        selectOption(){
            const self = this;
            const option = [...self.options]
            if(self.useDefaultMsg){
                option.unshift({ text : self.placeholder, value: null });
            }

            return self.inputValue == null || self.inputValue === ''
                ? option
                : option.filter((opt) => {
                    return opt.text.toLowerCase().includes(self.inputValue.toLowerCase())
                });
        },
    },
    watch: {
        options() {
            const self = this;
            self.resetInputText();
        },
        selected() {
            const self = this;
            self.$emit('update:modelValue', self.selected.value);
        }
    },
    methods: {
        resetInputText() {
            const self = this;
            if (self.selectOption && self.selectOption.length > 0) {
                if (!self.selected || self.selected.value == null) {
                    self.selected = self.selectOption[0];
                }
            }
        },
        placeholderDisable() {
            const self = this;
            if (!self.selected || !self.selected.value) {
                self.selected = { text : "", value: null };
            }
        },
    },
    mounted() {
        const self = this;
        self.resetInputText();
    },
};
</script>

<template>
    <div class="relative">
        <Combobox as="div" v-model="selected" :disabled="readonly">
            <ComboboxLabel class="text-sm font-light text-zinc-500" v-show="name"> {{ name }} </ComboboxLabel>
            <div class="mt-1 relative">
                <ComboboxInput
                    class="bg-white relative border border-gray-300 rounded-md shadow-sm pl-3
                            pr-10 py-2 text-left cursor-default max-w-6xl
                            focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    :class="{
                        'w-96': !full && !width14 && !width20 && !width24 && !width36 && !width40 && !width52 && !width60 && !width72,
                        'w-full': full,
                        'w-14': width14,
                        'w-20': width20,
                        'w-24': width24,
                        'w-36': width36,
                        'w-40': width40,
                        'w-52': width52,
                        'w-60': width60,
                        'w-72': width72,
                        'h-full': hFull,
                        'h-11': height11,
                        'h-12': height12,
                        'bg-gray-100': readonly,
                        'text-gray-400': selected != null && !selected.value }"
                    :readonly="readonly"
                    :required="required"
                    :displayValue="displayInputValue"
                    @change="inputValue = $event.target.value"
                    @focusin="placeholderDisable"
                    @focusout="resetInputText"
                />

                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2"
                    @click="inputValue = null"
                    v-show="selectOption">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ComboboxOptions
                        class="absolute z-10 mt-1  max-w-6xl bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                        :class="{
                            'w-96': !full && !width14 && !width20 && !width24 && !width36 && !width40 && !width52 && !width60 && !width72,
                            'w-full': full,
                            'w-14': width14,
                            'w-20': width20,
                            'w-24': width24,
                            'w-36': width36,
                            'w-40': width40,
                            'w-52': width52,
                            'w-60': width60,
                            'w-72': width72}"
                    >
                        <div
                            v-if="selectOption.length === 0 && inputValue !== ''"
                            class="relative cursor-default select-none px-4 py-2 text-gray-700"
                        >
                            Nothing found.
                        </div>

                        <ComboboxOption as="template" v-for="item in selectOption" :key="item.value" :value="item" v-slot="{ active, selected }" >
                            <li :class="[active ? 'text-sm text-white blu' : 'text-sm font-light text-zinc-500', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                                <span
                                    :class="{ 'font-semibold' : selected,  'font-normal' : !selected, 'min-w-fit' : minWFit  }"
                                    class="block truncate"
                                >
                                    {{ item.text }}
                                </span>
                                <span v-if="selected" :class="[active ? 'text-white' : 'blu-text', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                </transition>
            </div>
        </Combobox>
        <p class="mt-3.5 text-xs font-normal text-red-500" v-show="error">{{ error }}</p>
    </div>
</template>
<style scoped>
.blu{
    background: #4361EE;
}
.blu:hover{
    background: #4361EE;
}
.blu-text{
    color:#4361EE;
}
.blu:focus{
    outline: 1px solid #4361EE;
}
.blu-border{
    border: 1px solid #4361EE;
}
</style>