import { LOAN_TYPE, PAYROLL_PAYMENT_ON, PAYROLL_FREQUENCY } from "@/pages/payroll/payroll-settings/tab/constants/payroll-template-enum";

const LOAN_TYPE_OPTION = Object.freeze([
    {
        text : "HDMF CALAMITY LOAN",
        value : LOAN_TYPE.HDMF_CALAMITY_LOAN
    },
    {
        text : "HDMF HOUSE LOAN",
        value : LOAN_TYPE.HDMF_HOUSE_LOAN
    },
    {
        text : "HDMF SALARY LOAN",
        value : LOAN_TYPE.HDMF_SALARY_LOAN
    },
    {
        text : "HDMF SALARY LOAN (DELINQUENT)",
        value : LOAN_TYPE.HDMF_SALARY_LOAN_DELINQUENT
    },
    {
        text : "SSS CALAMITY LOAN",
        value : LOAN_TYPE.SSS_CALAMITY_LOAN
    },
    {
        text : "SSS EMERGENCY LOAN",
        value : LOAN_TYPE.SSS_EMERGENCY_LOAN
    },
    {
        text : "SSS HOUSING LOAN",
        value : LOAN_TYPE.SSS_HOUSING_LOAN
    },
    {
        text : "SSS SALARY LOAN",
        value : LOAN_TYPE.SSS_SALARY_LOAN
    },
    {
        text : "SSS SALARY LOAN (CONDONATION)",
        value : LOAN_TYPE.SSS_SALARY_LOAN_CONDONATION
    },
    {
        text : "SSS SALARY LOAN (SLERP)",
        value : LOAN_TYPE.SSS_SALARY_LOAN_SLERP
    },
    {
        text : "SSS SALARY LOAN (SLERP3)",
        value : LOAN_TYPE.SSS_SALARY_LOAN_SLERP3
    },
    {
        text : "Company LOAN",
        value : LOAN_TYPE.COMPANY_LOAN
    }
]);

const PAYROLL_PAYMENT_ON_OPTION = Object.freeze([
    {
        text : "1st Payroll",
        value : PAYROLL_PAYMENT_ON.FIRST
    },
    {
        text : "2nd Payroll",
        value : PAYROLL_PAYMENT_ON.SECOND
    },
    {
        text : "3rd Payroll",
        value : PAYROLL_PAYMENT_ON.THIRD
    },
    {
        text : "4th Payroll",
        value : PAYROLL_PAYMENT_ON.FOURTH
    },
    {
        text : "",
        value : PAYROLL_PAYMENT_ON.NONE
    }
]);

const PAYROLL_FREQUENCY_OPTION = Object.freeze([
    {
        text : "Monthly",
        value : PAYROLL_FREQUENCY.MONTHLY,
        per : "Per Month"
    },
    {
        text : "Semi-Monthly",
        value : PAYROLL_FREQUENCY.SEMI_MONTHLY,
        per : "Per Semi-Month"
    },
    {
        text : "Weekly",
        value : PAYROLL_FREQUENCY.WEEKLY,
        per : "Per Week"
    },
    {
        text : "Only 13th Month Pay",
        value : PAYROLL_FREQUENCY.ONLY_THIRTEEN_MONTH_PAY,
        per : ""
    }
]);

export {
    LOAN_TYPE_OPTION,
    PAYROLL_PAYMENT_ON_OPTION,
    PAYROLL_FREQUENCY_OPTION
};