<template>
    <div class="border w-[600px] bg-white border-[#EAEDF7] rounded-[10px]">
        <table class="divide-y divide-gray-300 w-full border-black border-opacity-10">
            <thead class="bg-[#F8F8FD] w-full">
                <tr class="bg-gray-200">
                    <th scope="col" class="px-2 py-3.5 text-center text-sm text-gray-900">Date</th>
                    <th scope="col" class="px-2 py-3.5 text-center text-sm text-gray-900">Type</th>
                    <th scope="col" class="px-2 py-3.5 text-center text-sm text-gray-900">Hours</th>
                    <th scope="col" class="px-2 py-3.5 text-center text-sm text-gray-900">Basic Hourly Wage</th>
                    <th scope="col" class="px-2 py-3.5 text-center text-sm text-gray-900">Deduct Amount</th>
                    <th scope="col" class="px-2 py-3.5 text-center text-sm text-gray-900"></th>
                </tr>
            </thead>
            <tbody class=" bg-white table-fixed">
                <tr v-for="(detail, index) in deductAmountDetailList" v-bind:key="index">
                    <td class="whitespace-normal px-3 py-4 text-sm text-center text-gray-900">{{ detail.deductDate }}</td>
                    <td class="whitespace-normal px-3 py-4 text-sm text-center text-gray-900">{{ detail.deductType }}</td>
                    <td class="whitespace-normal px-3 py-4 text-sm text-center text-gray-900">{{ getHHmmStrFromMin(detail.deductHours) }}</td>
                    <td class="whitespace-normal px-3 py-4 text-sm text-center text-gray-900">{{ fitDecimalFormatWithComma(detail.hourlyWageAmount, 5) }}</td>
                    <td class="whitespace-normal px-3 py-4 text-sm text-center text-gray-900">{{ fitDecimalFormatWithComma(detail.deductAmount, 5) }}</td>
                    <td class="whitespace-normal px-3 py-4 text-sm text-center text-gray-900"></td>
                </tr>
                <tr class="bg-gray-200">
                    <td colspan="3" class="whitespace-normal px-3 py-4 text-sm text-center text-gray-900">Total Deduct Amount</td>
                    <td colspan="3" class="whitespace-normal px-3 py-4 text-sm text-center text-gray-900">{{ fitDecimalFormatWithComma(getTotalDeductAmount()) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { NumberFormatUtil } from "@/plugins/app-util";

export default {
    components :{
    },
    emits : ['update:modelValue', 'afterClose'],
    props : {
        modelValue : Boolean,
        deductAmountDetailList : Array
    },
    data(){
        return {
        }
    },
    mounted() {
    },
    methods : {
        hidePopup() {
            const self = this;
            self.$emit('update:modelValue', false);
            self.$emit('afterClose');
        },
        getHHmmStrFromMin( minutesTotal ){
            minutesTotal = minutesTotal || 0;
            const hours = Math.floor(minutesTotal / 60);
            const minutes = minutesTotal % 60;

            let returnStr = hours ? `${hours}H` : "";
            returnStr += minutes ? `${minutes}M` : "";
            returnStr = returnStr || "0H";
            return returnStr;
        },
        getTotalDeductAmount() {
            const self = this;
            
            let total = 0;
            self.deductAmountDetailList.forEach((deduct) => {
                total += deduct.deductAmount;
            });

            return total;
        },
        fitDecimalFormatWithComma : NumberFormatUtil.fitDecimalFormatWithComma,
    }
}
</script>