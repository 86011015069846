<script>
import ElementsTooltipSingleton from "@/components/elements/ElementsTooltipSingleton.vue";

export default{
    components : {
        ElementsTooltipSingleton,
    },
    props : {
        selectOption : Array,
        name : String,
        modelValue : [ String, Boolean, Number ],
        readonly : Boolean,
        description: String,
        useTooltip: Boolean,
    },
    emits : [ 'update:modelValue', 'changeButton'],
    methods : {
        // -----------------------------------------------------------------------------
        loadTooltip(ele, msgFn, options ){
            this.__tooltipComponent__hidden__.loadBasic(ele, msgFn, options);
        },
        loadTooltipElementReady(ele){
            if( ele == null ) return; // auto-reload, so element can be null sometime.
            this.__tooltipComponent__hidden__ = ele;
        },
        // -----------------------------------------------------------------------------
        toggleButton( item ){
            const self = this;
            if( self.readonly ) return;
            self.$emit('update:modelValue', item);
            self.$emit('changeButton');
        }
    },
    computed:{
        selectedButton(){
            const self = this;
            let selected = self.modelValue
            
            if( ! self.valueArray.includes(self.modelValue) || !self.modelValue  ){
                selected = self.valueArray[0];
            }
            
            self.$emit('update:modelValue', selected);
            return selected; 
        },
        valueArray(){
            const self = this;
            return self.selectOption.map( option => option.value );
        } 
    },
}

</script>

<template>
    <div class="flex flex-col">
        <ElementsTooltipSingleton
            :ref="(el)=>{ loadTooltipElementReady(el); }"
        />

        <label class=" mb-[9px]"> 
            <div class="text-[14px] text-[#504F54] font-bold"> {{ name }} </div>
            <p class="text-gray-400 text-[14px]"> {{ description }} </p>
        </label>
        <div class="flex">
            <template v-for="(item, index) in selectOption" :key="index">
                <button
                    v-if="item.value === modelValue"
                    class="mr-[20px] h-[48px] w-[164px] font-medium text-[14px] text-[#6B7280] border-[#D6DDEB] border-[1px] rounded-[8px] border-solid border-1 blu shadow-sm hover-color"
                    :class="{ 'bg-gray-100': readonly }"
                    @click="toggleButton(item.value)">
                    {{ item.text }}
                </button>

                <button
                    v-else
                    class="mr-[20px] h-[48px] w-[164px] font-medium text-[14px] text-[#6B7280] border-[#D6DDEB] border-[1px] rounded-[8px] hover-color"
                    :class="{ 'bg-gray-100': readonly }"
                    @click="toggleButton(item.value)"
                    :ref="useTooltip ? (el)=>{ loadTooltip(el, ()=>{
                        return item.tooltip
                    })} : ''">
                    {{ item.text }}
                </button>
            </template> 
        </div>
    </div>
</template>
<style scoped>
    .blu{
      border: 1px solid #4261EE;
      color:#4261EE;
    }
    .hover-color:hover {
        border: none;
        color:#4261EE;
        background: #E8EBFA;
    }
</style>
