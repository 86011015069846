<script>
import AppAside from '@/components/AppAside.vue';
import AppMain from '@/components/main/AppMain.vue';
import ElementsTooltipSingleton from '@/components/elements/ElementsTooltipSingleton.vue';
import AppPopupHeaderless from '@/components/AppPopupHeaderless'
import ContractPopup from "@/pages/flex_ben/topup/contract/contract-popup.vue"
import moment from 'moment';

export default {
    components: {
        AppAside,
        AppMain,
        ElementsTooltipSingleton,
        AppPopupHeaderless,
        ContractPopup
    },
    mounted() {
        const self = this;

        self.getAvailablePoint();
        self.getTotalSummary();
        self.getEventOfMonth();
    },
    data() {
        return {
            availableWalletAmount: 0,
            previousMonthMileage: 0,

            topupSum: 0,
            transSum: 0,
            transferredSum: 0,
            deductedSum: 0,
            usedSum: 0,
            thisMonthTransferredSum: 0,
            thisMonthUsedSum: 0,
            previousMonthTransferredSum: 0,
            previousMonthUsedSum: 0,

            scheduleEvents: [],
            title: document.title || 'Summary',
            insufficientPointsPopup: false,
            contactPopup: false,
            periodTransferTypes: [
                { text: 'Weekly', value: 'WEEKLY' },
                { text: 'Monthly', value: 'MONTHLY' },
                { text: 'Quarterly', value: 'QUARTERLY' },
                { text: 'Annually', value: 'YEARLY' },
                { text: 'One-Time', value: 'SPECIAL_DAY' },
            ],
            employeeTypes: [
                { text: 'Regular', value: 'FULL_TIME' },
                { text: 'Probationary', value: 'PROBATION' },
                { text: 'Contractual', value: 'FIXED_TERM_CONTRACT' },
                { text: 'Project Base', value: 'PROJECT_BASE' },
                { text: 'Outsourced', value: 'OUTSOURCE' },
                { text: 'Intern/Part-Time', value: 'TEMPORARY' },
                { text: 'Etc', value: 'ETC' },
            ],
        }
    },
    computed: {
        comparedToLastMonthAvailable() {
            const self = this;
            let percent = 0;
            const thisMonth = self.availableWalletAmount;
            const previousMonth = self.previousMonthMileage;

            if (thisMonth == null || previousMonth == null || thisMonth == 0 || previousMonth == 0) {
                return 0;
            }

            percent = ((thisMonth - previousMonth) / previousMonth) * 100;
            return Math.round(percent);
        },
        comparedToLastMonthTransferred() {
            const self = this;
            let percent = 0;
            const thisMonth = -self.thisMonthTransferredSum;
            const previousMonth = -self.previousMonthTransferredSum;

            if (thisMonth == null || previousMonth == null || thisMonth == 0 || previousMonth == 0) {
                return 0;
            }

            percent = ((thisMonth - previousMonth) / previousMonth) * 100;
            return Math.round(percent);
        },
        comparedToLastMonthUsed() {
            const self = this;
            let percent = 0;
            const thisMonth = -self.thisMonthUsedSum;
            const previousMonth = -self.previousMonthUsedSum;

            if (thisMonth == null || previousMonth == null || thisMonth == 0 || previousMonth == 0) {
                return 0;
            }

            percent = ((thisMonth - previousMonth) / previousMonth) * 100;
            return Math.round(percent);
        },
    },
    watch: {
        availableWalletAmount() {
            const self = this;
            self.pointShortageCalculate();
        },
        scheduleEvents() {
            const self = this;
            self.pointShortageCalculate();
        }
    },
    methods: {
        // -----------------------------------------------------------------------------
        loadTooltip(ele, msgFn) {
            this.__tooltipComponent__hidden__.loadBasic(ele, msgFn, { placement: 'right' });
        },
        loadTooltipElementReady(ele) {
            if (ele == null) return; // auto-reload, so element can be null sometime.
            this.__tooltipComponent__hidden__ = ele;
        },
        // -----------------------------------------------------------------------------
        getAvailablePoint() {
            const self = this;

            const url = self.$api('uri', 'get-availablePoint');
            self.$axios
                .get(url)
                .then((res) => {
                    self.availableWalletAmount = res.data.data.availableWalletAmount;
                    self.previousMonthMileage = res.data.data.previousMonthMileage;
                })
                .catch((err) => {
                    console.log('err : ', err);
                });
        },
        getTotalSummary() {
            const self = this;

            const url = self.$api('uri', 'get-total-summary');
            self.$axios
                .get(url)
                .then((res) => {
                    const resData = res.data.data;

                    // HRnFLEX가 회사에게 준 포인트
                    self.topupSum = resData.topupSum;
                    // transferredSum + deductedSum
                    self.transSum = resData.transSum;
                    // 회사가 사용한 포인트(직원에게 부여한 포인트 - 항상 음수)
                    self.transferredSum = resData.transferredSum;
                    // 회사가 직원에게서 회수한 포인트
                    self.deductedSum = resData.deductedSum;
                    self.usedSum = resData.usedSum;

                    self.thisMonthTransferredSum = resData.thisMonthTransferredSum || 0;
                    self.thisMonthUsedSum = resData.thisMonthUsedSum || 0;
                    self.previousMonthTransferredSum = resData.previousMonthTransferredSum || 0;
                    self.previousMonthUsedSum = resData.previousMonthUsedSum || 0;
                })
                .catch((err) => {
                    console.log('err : ', err);
                });
        },
        getEventOfMonth() {
            const self = this;

            const url = self.$api('uri', 'get-event-of-month');
            self.$axios
                .get(url)
                .then((res) => {
                    self.scheduleEvents = res.data.data.scheduleEvents;
                })
                .catch((err) => {
                    console.error('err : ', err);
                });
        },
        pointShortageCalculate() {
            const self = this;
            const scheduleList = self.scheduleEvents;
            let wallet = self.availableWalletAmount;
            if (scheduleList == null) {
                return;
            } else if (!(scheduleList instanceof Array)) {
                return;
            } else if (wallet == null) {
                return;
            }

            scheduleList.forEach( schedule => {
                if (schedule.transferExecuteStatus == 'READY') {
                    wallet += schedule.totalMileageVolume;
                    if (wallet < 0) {
                        //포인트 부족
                        schedule.pointShortage = true;
                    } else {
                        schedule.pointShortage = false;
                    }
                }
            })
        },
        fmtTimezoneToMMddyyyy(timezone) {
            // format : timeZone = 2022-09-13T07:04Z
            if (timezone == null) return;
            return moment(timezone, 'YYYY-MM-DDTHH:mm:ssZ').format('MM/DD/YYYY HH:mm');
        },
        setEmpType(employeeTypeCheckList) {
            const list = JSON.parse(employeeTypeCheckList);
            let employeeTypeChecked = [];

            list.map((type) => {
                if (type.checked == true) employeeTypeChecked.push(type.employeeType);
            });

            return employeeTypeChecked;
        },
        numberWithCommas(number) {
            if (!number) return;
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        allocationTermsConverter(allocation) {
            let changed = '';
            const termChange = {
                SAME: 'All Equal',
                JOB_TITLE: 'Tier Based',
                DEPARTMENT: 'Team Based',
            };

            if (!allocation || !Object.keys(termChange).includes(allocation)) {
                return changed;
            }
            changed = termChange[allocation];
            return changed;
        },
        insufficientPoints() {
            const self = this;
            self.insufficientPointsPopup = true;
        },
        openContactPopup() {
            const self = this;
            self.insufficientPointsPopup = false;
            self.contactPopup = true;
        },
        convertPeriodTransferTypeName(code) {
            const self = this;
            let name = null;
            self.periodTransferTypes.forEach((type) => {
                if (type.value == code) {
                    name = type.text;
                }
            });

            return name;
        },
        convertEmployeeTypeName(code) {
            const self = this;
            let name = null;
            self.employeeTypes.forEach((type) => {
                if (type.value == code) {
                    name = type.text;
                }
            });

            return name;
        },
    },
};
</script>

<template>
    <div id="app" class="min-w-[1024px] min-h-[100vh] flex">
        <ElementsTooltipSingleton
            :ref="
                (el) => {
                    loadTooltipElementReady(el);
                }
            " />
        <AppAside />
        <AppMain>
            <div>
                <h3 class="text-sm font-medium text-gray-500">FlexBen</h3>
                <h1 class="mt-1.5 text-3xl font-semibold flex items-center gap-1">
                    {{ title }}
                </h1>
            </div>
            <h1 class="text-[24px] text-[#2E2C34] font-bold mt-[24px] flex flex-row items-center">
                <span>Points</span>
                <!-- 포인트 조회 기간이 현재년도에서 전체 기간으로 변경되어 해당 information 안보여주는 것으로 변경 -->
                <!-- <InformationCircleIcon class="h-5 w-5 ml-2 opacity-40" aria-hidden="true"
                    :ref="(el)=>{ loadTooltip(el, ()=>{
                    return 'Except for Available points, only this year values are displayed.';
                })}"/> -->
            </h1>
            <div class="mt-[10px] w-full max-w-7xl flex">
                <div class="w-[24%] h-[8.75em] border shadow rounded box-style-border bg-[#fff] text-center mr-[17px]">
                    <p class="text-[16px] text-[#25324B] pt-[13px]">Total Points Topped-up(A+B)</p>
                    <div class="mt-[11px]">
                        <span class="text-[36px]">{{ numberWithCommas(topupSum) }}</span>
                        <span class="text-[16px] ml-1">points</span>
                    </div>
                </div>
                <div class="w-[24%] h-[8.75em] border shadow rounded box-style-border bg-[#fff] text-center mr-[17px]">
                    <p class="text-[16px] text-[#25324B] pt-[13px]">Available Points(A)</p>
                    <div class="mt-[11px]">
                        <span class="text-[36px]">{{ numberWithCommas(availableWalletAmount) }}</span>
                        <span class="text-[16px] ml-1">points</span>
                    </div>
                    <div class="mt-[1px]">
                        <div v-if="comparedToLastMonthAvailable > 0" class="flex justify-center items-center gap-2">
                            <div>
                                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.20754 1.02938C5.60785 0.509372 6.39205 0.509372 6.79236 1.02938L11.6888 7.39002C12.195 8.04759 11.7262 9.00001 10.8964 9.00001H1.10353C0.273686 9.00001 -0.195076 8.04759 0.311121 7.39002L5.20754 1.02938Z"
                                        fill="#44CC82" />
                                </svg>
                            </div>
                            <div class="text-[12px]">{{ comparedToLastMonthAvailable }}% compared to last month.</div>
                        </div>
                        <div v-else-if="comparedToLastMonthAvailable == 0" class="flex justify-center items-center gap-2">
                            <div>
                                <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 11H11" stroke="#AAB0BA" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                            </div>
                            <div class="text-[12px]">{{ comparedToLastMonthAvailable }}% compared to last month.</div>
                        </div>
                        <div v-else class="flex justify-center items-center gap-2">
                            <div>
                                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.79258 8.60999C6.39228 9.13 5.60807 9.13 5.20777 8.60999L0.311344 2.24935C-0.194853 1.59178 0.273911 0.639358 1.10375 0.639359L10.8966 0.639359C11.7264 0.639359 12.1952 1.59178 11.689 2.24935L6.79258 8.60999Z"
                                        fill="#FF7777" />
                                </svg>
                            </div>
                            <div class="text-[12px]">{{ -comparedToLastMonthAvailable }}% compared to last month.</div>
                        </div>
                    </div>
                </div>

                <div class="w-[24%] h-[8.75em] border shadow rounded box-style-border bg-[#fff] text-center mr-[17px]">
                    <p class="text-[16px] text-[#25324B] pt-[13px]">Transferred Points(B)</p>
                    <div class="mt-[11px]">
                        <span class="text-[36px]">{{ numberWithCommas(-transSum) }}</span>
                        <span class="text-[16px] ml-1">points</span>
                    </div>
                    <div class="mt-[1px]">
                        <div v-if="comparedToLastMonthTransferred > 0" class="flex justify-center items-center gap-2">
                            <div>
                                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.20754 1.02938C5.60785 0.509372 6.39205 0.509372 6.79236 1.02938L11.6888 7.39002C12.195 8.04759 11.7262 9.00001 10.8964 9.00001H1.10353C0.273686 9.00001 -0.195076 8.04759 0.311121 7.39002L5.20754 1.02938Z"
                                        fill="#44CC82" />
                                </svg>
                            </div>
                            <div class="text-[12px]">{{ comparedToLastMonthTransferred }}% compared to last month.</div>
                        </div>
                        <div v-else-if="comparedToLastMonthTransferred == 0" class="flex justify-center items-center gap-2">
                            <div>
                                <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 11H11" stroke="#AAB0BA" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                            </div>
                            <div class="text-[12px]">{{ comparedToLastMonthTransferred }}% compared to last month.</div>
                        </div>
                        <div v-else class="flex justify-center items-center gap-2">
                            <div>
                                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.79258 8.60999C6.39228 9.13 5.60807 9.13 5.20777 8.60999L0.311344 2.24935C-0.194853 1.59178 0.273911 0.639358 1.10375 0.639359L10.8966 0.639359C11.7264 0.639359 12.1952 1.59178 11.689 2.24935L6.79258 8.60999Z"
                                        fill="#FF7777" />
                                </svg>
                            </div>
                            <div class="text-[12px]">{{ -comparedToLastMonthTransferred }}% compared to last month.</div>
                        </div>
                    </div>
                </div>
                <div class="w-[24%] h-[8.75em] border shadow rounded box-style-border bg-[#fff] text-center mr-[17px]">
                    <p class="text-[16px] text-[#25324B] pt-[13px]">Used Points</p>

                    <div class="mt-[11px]">
                        <span class="text-[36px]">{{ numberWithCommas(usedSum) }}</span>
                        <span class="text-[16px] ml-1">points</span>
                    </div>
                    <div class="mt-[1px]">
                        <div v-if="comparedToLastMonthUsed > 0" class="flex justify-center items-center gap-2">
                            <div>
                                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.20754 1.02938C5.60785 0.509372 6.39205 0.509372 6.79236 1.02938L11.6888 7.39002C12.195 8.04759 11.7262 9.00001 10.8964 9.00001H1.10353C0.273686 9.00001 -0.195076 8.04759 0.311121 7.39002L5.20754 1.02938Z"
                                        fill="#44CC82" />
                                </svg>
                            </div>
                            <div class="text-[12px]">{{ comparedToLastMonthUsed }}% compared to last month.</div>
                        </div>
                        <div v-else-if="comparedToLastMonthUsed == 0" class="flex justify-center items-center gap-2">
                            <div>
                                <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 11H11" stroke="#AAB0BA" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                            </div>
                            <div class="text-[12px]">{{ comparedToLastMonthUsed }}% compared to last month.</div>
                        </div>
                        <div v-else class="flex justify-center items-center gap-2">
                            <div>
                                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.79258 8.60999C6.39228 9.13 5.60807 9.13 5.20777 8.60999L0.311344 2.24935C-0.194853 1.59178 0.273911 0.639358 1.10375 0.639359L10.8966 0.639359C11.7264 0.639359 12.1952 1.59178 11.689 2.24935L6.79258 8.60999Z"
                                        fill="#FF7777" />
                                </svg>
                            </div>
                            <div class="text-[12px]">{{ -comparedToLastMonthUsed }}% compared to last month.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex-1 mt-9 w-full max-w-7xl">
                <div id="employees" class="mt-12">
                    <p class="text-[24px] text-[#2E2C34] font-bold mt-[24px]">Points Transfer Schedule</p>
                    <div class="mt-5 rounded bg-white shadow border box-style-border">
                        <div class="flex justify-between items-center h-[3.875rem]">
                            <h3 class="pl-5 text-base font-medium text-slate-500 w-[50px] text-center">No</h3>
                            <h3 class="py-4 text-base font-medium text-slate-500 flex-1 text-center">Date</h3>
                            <h3 class="py-4 text-base font-medium text-slate-500 flex-1 text-center">
                                FlexBen <br />
                                Type
                            </h3>
                            <h3 class="py-4 text-base font-medium text-slate-500 flex-1 text-center">
                                Employee <br />
                                Type
                            </h3>
                            <h3 class="py-4 text-base font-medium text-slate-500 flex-1 text-center">
                                Transfer <br />
                                Frequency
                            </h3>
                            <h3 class="py-4 text-base font-medium text-slate-500 flex-1 text-center">
                                Distribution <br />
                                Setting
                            </h3>
                            <h3 class="py-4 text-base font-medium text-slate-500 flex-1 text-center">
                                Estimated <br/>
                                Transfer Points
                            </h3>
                            <h3 class="py-4 text-base font-medium text-slate-500 flex-1 text-center">
                                Transferred <br/>
                                Status
                            </h3>
                            <h3 class="py-4 text-base font-medium text-slate-500 flex-1 text-center">
                                Point <br/>
                                Shortage
                            </h3>
                        </div>
                        <div class="w-full h-px bg-gray-200"></div>
                        <div class="overflow-auto">
                            <div class="min-w-full w-fit min-h-[8.5rem]">
                                <div
                                    v-for="(event, index) in scheduleEvents"
                                    :key="index"
                                    href="javascript:void(0)"
                                    class="min-w-[656px] flex justify-between items-center py-2.5 w-full min-h-[4.25rem]"
                                    :class="{ 'bg-slate-50': index % 2 !== 0 }">
                                    <div class="pl-5 w-[50px] text-sm font-normal text-center truncate">
                                        {{ index + 1 }}
                                    </div>
                                    <div class="flex-1 text-sm font-normal text-center truncate">
                                        {{ fmtTimezoneToMMddyyyy(event.finishExecuteDateTime) }}
                                        {{ fmtTimezoneToMMddyyyy(event.executeDatetime) }}
                                    </div>
                                    <div class="flex-1 text-sm font-normal text-center truncate">
                                        {{ event.flexbenPolicyTransferName }}
                                    </div>
                                    <div class="flex-1 text-sm font-normal text-center truncate">
                                        <template v-if="setEmpType(event.employeeTypeCheckList).length == 7"> All </template>
                                        <template v-else>
                                            <template v-for="(empCheckType, index) in setEmpType(event.employeeTypeCheckList)" v-bind:key="index">
                                                <!-- {{ setEmpType(event.employeeTypeCheckList) }} -->
                                                <p>
                                                    {{ convertEmployeeTypeName(empCheckType) + (index !== setEmpType(event.employeeTypeCheckList).length - 1 ? ',' : '') }}
                                                </p>
                                            </template>
                                        </template>
                                    </div>
                                    <div class="flex-1 text-sm font-normal text-center truncate">
                                        {{ convertPeriodTransferTypeName(event.periodTransferType) }}
                                    </div>
                                    <div class="flex-1 text-sm font-normal text-center truncate">
                                        {{ allocationTermsConverter(event.distributionTransferType) }}
                                    </div>
                                    <div class="flex-1 text-sm font-normal text-center truncate">
                                        {{ numberWithCommas( - (event.totalMileageVolume) ) }}
                                    </div>
                                    <div class="flex-1 text-sm font-normal text-center truncate">
                                        <template v-if="event.transferExecuteStatus == 'DONE'">
                                            <p class="text-[#037D43]">Success</p>
                                        </template>
                                        <template v-else-if="event.transferExecuteStatus == 'FAIL'">
                                            <p class="text-[#CC2525]">Fail</p>
                                        </template>
                                        <template v-else>
                                            <p class="text-[#41568B]">Pending</p>
                                        </template>
                                    </div>
                                    <div class="flex-1 text-sm font-normal text-center truncate">
                                        <template v-if="event.transferExecuteStatus == 'READY' && event.pointShortage != null">
                                            <template v-if="event.pointShortage">
                                                <button @click="insufficientPoints">
                                                    <div class="bg-[#FCE1E1] rounded-[12px] inline-flex items-center py-1 px-2 text-[#CC2525]">
                                                        Insufficient
                                                        <img class="ml-1" src="@/assets/img/exclamation_red.svg" alt="" />
                                                    </div>
                                                </button>
                                            </template>
                                            <template v-else>
                                                <div class="bg-[#DCF8E6] rounded-[12px] inline-flex py-1 px-2 text-[#146E33]">Sufficient</div>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- FIXME 페이지네이션 하게 될까 ? -->
                        <!-- <div class="w-full h-28 flex justify-center items-center">
                        <div class="flex gap-0.5">
                            <a
                            href="javascript:void(0)"
                            class="w-11 h-11 flex justify-center items-center"
                            >
                            <img
                                src="@/assets/img/elements-pagination-1.svg"
                                alt="elements-pagination-1"
                            />
                            </a>
                            <a
                            href="javascript:void(0)"
                            class="rounded-lg w-11 h-11 bg-indigo-600 flex justify-center items-center text-white transition-all duration-500"
                            >
                            <span>1</span>
                            </a>
                            <a
                            href="javascript:void(0)"
                            class="rounded-lg w-11 h-11 hover:bg-indigo-600 flex justify-center items-center hover:text-white transition-all duration-500"
                            >
                            <span>2</span>
                            </a>
                            <a
                            href="javascript:void(0)"
                            class="w-11 h-11 flex justify-center items-center"
                            >
                            <img
                                src="@/assets/img/elements-pagination-2.svg"
                                alt="elements-pagination-2"
                            />
                            </a>
                        </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </AppMain>

        <Teleport to="body">
            <AppPopupHeaderless v-model="insufficientPointsPopup" title="Insufficient Points Alert" @buttonEvent="openContactPopup">
                You have insufficient points to complete the transfer. <br />
                Top-up your points now to proceed.
            </AppPopupHeaderless>
            <ContractPopup
                v-model="contactPopup"
            />
        </Teleport>
    </div>
</template>
<style scoped>
.box-style-border {
    border: 1px solid #d1d5db;
}
</style>