<script>
import ElementsPagination from '@/components/elements/ElementsPagination.vue';
import ElementsSelect from '@/components/elements/ElementsSelect.vue';
import AppPopupHeaderless from '@/components/AppPopupHeaderless';
import StatusCircle from '@/components/employee/status-circle/status-circle';

export default {
    components: {
        ElementsPagination,
        ElementsSelect,
        AppPopupHeaderless,
        StatusCircle,
    },
    props: {
        transfer: Boolean,
        deduct: Boolean,
    },
    computed: {},
    emits: ['clickTransferButton', 'clickDeductButton'],
    data() {
        return {
            employees: [],
            choosedEmployees: [],

            currentPage: null,
            employeeCount: null,
            AccEmployeeCount: null,

            searchedText: null,
            searchedValues: null,

            availableWalletAmount: 0,
            searchJsonQuery: {
                limit: 10,
                offset: null,
                employeeSeqList: null,
                departmentSeq: null,
                jobTitleSeq: null,
            },

            searchInputStr: null,
            employeeOptions: [],
            departmentOptions: [],
            jobTitleOptions: [],
            employeePlaceholer: 'Search Employees',

            totalAmount: 0,

            emailMessage: '',
            alertMessage: '',
            confirmMessage: '',
            showAlertPopup: false,
            showConfirmPopup: false,
        };
    },
    mounted() {
        const self = this;

        self.getAvailablePoint();
        self.getEmployeesListData(true);
        self.getEmployeeOptions();
        self.getDepartmentOptions();
        self.getJobTitleOptions();
    },
    methods: {
        over20Charaters(word) {
            if (!word) return word;

            if (word.length < 21) return word;
            return word.substring(0, 20) + '...';
        },
        getAvailablePoint() {
            const self = this;

            const url = self.$api('uri', 'get-availablePoint');
            self.$axios
                .get(url)
                .then((res) => {
                    self.availableWalletAmount = res.data.data.availableWalletAmount;
                })
                .catch((err) => {
                    console.error('err : ', err);
                });
        },
        getDepartmentOptions() {
            const self = this;

            const url = self.$api('uri', 'get-department');
            const json_query = JSON.stringify({
                showRootNode: null,
            });
            self.$axios.get(url, { params: { json_query } }).then((res) => {
                const allType = {
                    text: 'All Departments',
                    value: null,
                };

                self.departmentOptions.push(allType);

                const options = res.data.data.list.map((dep) => ({
                    text: dep.departmentName,
                    value: dep.departmentSeq,
                }));

                self.departmentOptions.push(...options);
            });
        },
        getJobTitleOptions() {
            const self = this;

            const json_query = JSON.stringify({
                limit: -1,
                offset: 0,
            });
            const url = self.$api('uri', 'get-jobTitle');
            self.$axios.get(url, { params: { json_query } }).then((res) => {
                const allType = {
                    text: 'All Tiers',
                    value: null,
                };

                self.jobTitleOptions.push(allType);

                const options = res.data.data.list.map((title) => ({ text: title.jobTitleName, value: title.jobTitleSeq }));

                self.jobTitleOptions.push(...options);
            });
        },
        getEmployeeOptions() {
            const self = this;

            const url = self.$api('uri', 'get-employee-all');
            self.$axios
                .get(url)
                .then((res) => {
                    self.employeeOptions = res.data.data.list.map((emp) => ({ text: emp.employeeName, value: emp.employeeSeq }));
                })
                .catch((err) => {
                    console.error('err : ', err);
                });
        },
        getEmployeesListData(isInit = false, offset = 0) {
            const self = this;
            self.searchJsonQuery.offset = offset;

            if (offset == 0) {
                self.searchedText = self.$refs.employeeSearchBar.getSearchedText();
                self.searchedValues = self.$refs.employeeSearchBar.getSearchedValues();
            } else {
                self.$refs.employeeSearchBar.setInputText(self.searchedText);
            }

            self.searchJsonQuery.employeeSeqList = self.searchedValues;

            const url = self.$api('uri', 'get-wallet-employee-list');
            const json_query = JSON.stringify(self.searchJsonQuery);
            self.$axios
                .get(url, { params: { json_query } })
                .then((res) => {
                    self.employees = res.data.data.list;
                    self.employeeCount = res.data.data.total;

                    // self.currentPage = 1;
                    // if(isInit) self.tabs[0].count = res.data.data.total;
                })
                .catch((err) => {
                    console.error('err : ', err);
                });
        },
        afterClickPage(item) {
            const self = this;

            //for employee no 누적
            self.AccEmployeeCount = item;
            self.getEmployeesListData(false, item);
        },
        searchEmployeeData() {
            const self = this;

            self.AccEmployeeCount = 0;
            self.getEmployeesListData();
            self.currentPage = 1;
        },
        clickAddButton(selectEmp) {
            const self = this;

            //중복일경우
            if (self.choosedEmployees.some((emp) => emp.employeeSeq == selectEmp.employeeSeq)) return;

            //10명이상을 선택할 경우
            if (self.choosedEmployees.length >= 10) {
                self.openAlertPopup('You can only select up to 10 people.');
                return;
            }

            self.choosedEmployees.push(selectEmp);
        },
        clickDeleteButton(index) {
            const self = this;

            // self.employees.push ( self.choosedEmployees[index] );
            self.choosedEmployees.splice(index, 1);

            self.calTotalAmount();
        },
        clickSubmit() {
            const self = this;
            self.hideConfirmPopup();

            if (self.transfer) {
                self.submitTransfer();
            } else if (self.deduct) {
                self.submitDeduct();
            }
        },
        submitTransfer() {
            const self = this;

            //mileageVolumeFormat 제거
            let transfer = self.choosedEmployees.map((emp) => {
                // eslint-disable-next-line no-unused-vars
                const { mileageVolumeFormat, ...other } = emp;
                return other;
            });

            self.$emit('clickTransferButton', transfer, self.emailMessage);
        },
        clickTransferButton() {
            const self = this;

            if (self.checkValidation() != 0) {
                return;
            }

            // total amount 가 가지고 있는 것보다 큰 경우
            if (self.totalAmount > self.availableWalletAmount) {
                self.openAlertPopup('The amount of transfer is more than total');
                return;
            }

            self.openConfirmPopup('Are you sure you want to transfer points?');
        },
        submitDeduct() {
            const self = this;

            //mileageVolumeFormat 제거
            let deduct = self.choosedEmployees.map((emp) => {
                // eslint-disable-next-line no-unused-vars
                const { mileageVolumeFormat, ...other } = emp;
                return other;
            });

            self.$emit('clickDeductButton', deduct, self.emailMessage);
        },
        clickDeductButton() {
            const self = this;

            self.hideConfirmPopup();

            if (self.checkValidation() != 0) {
                return;
            }

            self.openConfirmPopup('Are you sure you want to deduct points?');
        },
        onInputMileage(event, employee) {
            const self = this;

            const input = event.target.value;
            let mileage = input.replace(/[^0-9]/g, '');

            employee.mileageVolume = mileage;

            self.numberWithCommas(mileage);

            employee.mileageVolumeFormat = self.numberWithCommas(mileage);

            self.calTotalAmount();
        },
        calTotalAmount() {
            const self = this;

            if (self.choosedEmployees.length == 0) return (self.totalAmount = 0);
            self.totalAmount = 0;

            self.choosedEmployees.map((employee) => {
                const points = Number(employee.mileageVolume);

                self.totalAmount += points;
            });
        },
        checkValidation() {
            const self = this;

            let validationCnt = 0;

            //validation ---------------------------------------------------------------------------------
            //중복 값 제거
            self.choosedEmployees = self.choosedEmployees.filter((character, idx) => {
                if (character.email == null || '') return;
                return self.choosedEmployees.findIndex((item) => item.email == character.email) === idx;
            });

            //mileageVolume 에 정수 외의 값을 입력했을 경우
            self.choosedEmployees.map((employee) => {
                const points = Number(employee.mileageVolume);
                if (Number.isNaN(points) || points < 1) {
                    validationCnt++;
                }
            });

            if (validationCnt > 0) {
                self.openAlertPopup('You need to enter the point amount.');
                return validationCnt++;
            }

            //아무도 선택하지 않았을 경우
            if (self.choosedEmployees.length == 0) {
                self.openAlertPopup('Please select at least one employee.');
                validationCnt++;
            }

            self.calTotalAmount();
            //validation ---------------------------------------------------------------------------------

            return validationCnt;
        },
        numberWithCommas(number) {
            if (!number) return;
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        openAlertPopup(message) {
            const self = this;
            self.alertMessage = message;
            self.showAlertPopup = true;
        },
        hideAlertPopup() {
            const self = this;
            self.showAlertPopup = false;
        },
        openConfirmPopup(message) {
            const self = this;
            self.confirmMessage = message;
            self.showConfirmPopup = true;
        },
        hideConfirmPopup() {
            const self = this;
            self.showConfirmPopup = false;
        },
    },
};
</script>

<template>
    <div class="mt-5 rounded border w-full max-w-7xl bg-white shadow box-style-border">
        <div class="w-full Class Properties p-10">
            <div class="w-full h-[124px] bg-[#4361EE] rounded-lg text-center">
                <p class="pt-[15px] text-[#fff] text-[16px]">Available Points</p>
                <p class="text-[#fff] text-[48px]">{{ numberWithCommas(availableWalletAmount) ?? 0 }}</p>
            </div>
        </div>

        <div class="flex justify-between items-center gap-5 flex-wrap p-5">
            <div class="flex justify-between items-center gap-5 flex-wrap">
                <ElementsSelect v-model="searchJsonQuery.departmentSeq" :options="departmentOptions" :width60="true" placeholder="All Departments" :useDefaultMsg="true" @change="searchEmployeeData" />
                <ElementsSelect v-model="searchJsonQuery.jobTitleSeq" :options="jobTitleOptions" :width60="true" placeholder="All Tiers" :useDefaultMsg="true" @change="searchEmployeeData" />
                <ElementsSearchBar
                    v-model="searchInputStr"
                    :options="employeeOptions"
                    :height12="true"
                    :width60="true"
                    :placeholder="employeePlaceholer"
                    @searchEvent="searchEmployeeData"
                    ref="employeeSearchBar"
                    :optionClickEvent="true"
                    :hasSearchButton="true" />
            </div>
        </div>

        <div class="transfer-grid px-5">
            <div>
                <div class="w-full py-10">
                    <div class="w-full h-[1px] bg-[#D9D9D9] relative">
                        <div class="px-5 text-[20px] bg-[#fff] absolute text-center left-[50%] translate-x-[-50%] top-[-15px] truncate">Choose employee</div>
                    </div>
                </div>
                <div class="h-[46.75rem] border-[#D6DDEB] border-[1px] rounded-lg overflow-hidden">
                    <div class="h-[4.25rem] flex justify-between items-center bg-[#F8F8F8] border-b-[1px]">
                        <h3 class="px-4 text-base font-medium text-slate-500 w-[3.125rem] text-center">No</h3>
                        <h3 class="py-4 text-base font-medium text-slate-500 w-[12.8125rem] text-center">Name</h3>
                        <h3 class="py-4 text-base font-medium text-slate-500 flex-1 text-center">
                            dept. <br />
                            & Tier
                        </h3>
                    </div>
                    <div class="overflow-auto max-h-[42.5rem]">
                        <div class="min-w-full">
                            <template v-for="(employee, index) in employees" v-bind:key="index">
                                <div class="flex justify-between items-center py-2.5 w-full" :class="{ 'bg-slate-50': index % 2 !== 0 }" @click="clickAddButton(employee)">
                                    <div class="w-[3.125rem] text-sm font-normal text-center truncate">
                                        {{ index + 1 + AccEmployeeCount }}
                                    </div>
                                    <div class="w-[12.8125rem] flex items-center gap-2 px-4">
                                        <StatusCircle :status-code="employee.employeeStatus" />
                                        <div class="ml-1 flex-1 text-base font-bold">
                                            {{ employee.employeeName }}
                                        </div>
                                    </div>
                                    <div class="flex-1 text-sm font-normal text-center">
                                        <div class="flex-1 text-base">
                                            <p class="font-bold">
                                                {{ employee.departmentName }}
                                            </p>
                                            <p>
                                                {{ employee.jobTitleName }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="w-full h-28 flex justify-center items-center">
                    <ElementsPagination v-model="currentPage" :totalContent="employeeCount" :contentsPerPage="searchJsonQuery.limit" @clickPage="afterClickPage" />
                </div>
            </div>

            <div class="flex justify-center items-center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.4142 12L11.7071 11.2929L5.70711 5.29289C5.31658 4.90237 4.68342 4.90237 4.29289 5.29289C3.90237 5.68342 3.90237 6.31658 4.29289 6.70711L9.58579 12L4.29289 17.2929C3.90237 17.6834 3.90237 18.3166 4.29289 18.7071C4.68342 19.0976 5.31658 19.0976 5.70711 18.7071L11.7071 12.7071L12.4142 12Z"
                        fill="#393C47" />
                    <path d="M13 6L19 12L13 18" stroke="#393C47" stroke-width="2" stroke-linecap="round" />
                </svg>
            </div>

            <div>
                <div class="w-full py-10">
                    <div class="w-full h-[1px] bg-[#D9D9D9] relative">
                        <div class="px-5 text-[20px] bg-[#fff] absolute text-center left-[50%] translate-x-[-50%] top-[-15px] truncate">
                            <template v-if="transfer"> Transfer List </template>
                            <template v-if="deduct"> Deduct List </template>
                        </div>
                    </div>
                </div>
                <div class="h-[46.75rem] border-[#D6DDEB] border-[1px] rounded-lg overflow-hidden">
                    <div class="h-[4.25rem] flex justify-between items-center bg-[#F8F8F8] border-b-[1px]">
                        <h3 class="px-4 text-base font-medium text-slate-500 w-[3.125rem] text-center">No</h3>
                        <h3 class="py-4 text-base font-medium text-slate-500 w-[7.8125rem] text-center">Name</h3>
                        <h3 class="py-4 text-base font-medium text-slate-500 w-[7.8125rem] text-center">
                            dept. <br />
                            & Tier
                        </h3>
                        <h3 class="py-4 text-base font-medium text-slate-500 w-[7.8125rem] text-center">Points Amount</h3>
                        <h3 class="py-4 text-base font-medium text-slate-500 w-[7.8125rem] text-center">Remark</h3>
                        <h3 class="py-4 text-base font-medium text-slate-500 w-[1.875rem] text-center"></h3>
                    </div>
                    <div class="overflow-auto max-h-[42.5rem]">
                        <div class="min-w-full">
                            <template v-for="(employee, index) in choosedEmployees" v-bind:key="index">
                                <div class="flex justify-between items-center py-2.5 w-full" :class="{ 'bg-slate-50': index % 2 !== 0 }">
                                    <div class="w-[3.125rem] text-sm font-normal text-center truncate">
                                        {{ index + 1 }}
                                    </div>
                                    <div class="w-[7.8125rem] flex items-center gap-2 px-4">
                                        <StatusCircle :status-code="employee.employeeStatus" />
                                        <div class="ml-1 flex-1 text-base font-bold">
                                            {{ employee.employeeName }}
                                        </div>
                                    </div>
                                    <div class="w-[7.8125rem] text-sm font-normal text-center">
                                        <div class="flex-1 text-base">
                                            <p class="font-bold">
                                                {{ employee.departmentName }}
                                            </p>
                                            <p>
                                                {{ employee.jobTitleName }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="w-[7.8125rem] px-2 text-sm font-normal text-center truncate">
                                        <ElementsInput v-model="employee.mileageVolumeFormat" placeholder="0" :full="true" @input="onInputMileage($event, employee)" />
                                    </div>
                                    <div class="w-[7.8125rem] px-2 text-sm font-normal text-center truncate">
                                        <ElementsInput v-model="employee.transactionNote" placeholder="Remark" :full="true" />
                                    </div>
                                    <button class="w-[1.875rem] flex justify-center items-center" @click="clickDeleteButton(index)">
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.66003 8.00016L8.00003 1.66016" stroke="#E73737" stroke-width="1.68021" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8.00003 8.00016L1.66003 1.66016" stroke="#E73737" stroke-width="1.68021" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="transfer" class="w-full p-10">
            <div class="w-full h-[1px] bg-[#D9D9D9] relative">
                <div class="w-[147px] bg-[#fff] absolute text-center left-[50%] translate-x-[-50%] top-[-10px]">Message</div>
            </div>
            <textarea
                v-model="emailMessage"
                placeholder="Message"
                class="mt-[50px] p-10 outline-0 w-full h-[114px] text-sm border rounded-lg bg-white transition-all duration-500 shadow shadow-gray-100" />
        </div>
        <div class="w-full p-10">
            <div class="w-full h-[1px] bg-[#D9D9D9] relative">
                <div class="w-[250px] bg-[#fff] absolute text-center left-[50%] translate-x-[-50%] top-[-10px]">Total Amount : {{ numberWithCommas(totalAmount) ?? 0 }} Point</div>
            </div>
            <button v-if="transfer" class="rounded mt-[50px] w-full text-center text-[#fff] h-[62px] leading-[62px] bg-[#4640DE]" @click="clickTransferButton">Transfer</button>
            <button v-if="deduct" class="rounded mt-[50px] w-full text-center text-[#fff] h-[62px] leading-[62px] bg-[#4640DE]" @click="clickDeductButton">Deduct</button>
        </div>
    </div>
    <Teleport to="body">
        <AppPopupHeaderless v-model="showAlertPopup" :hideTitle="true" min-width="32.75em" width="32.75em" height="13.5em">
            <div class="flex flex-col justify-center items-center space-y-4 p-5">
                <div class="p-6 space-y-6 text-center text-[20px] text-[#504F54]">{{ alertMessage }}</div>
                <button
                    class="h-[3.125rem] w-[10rem] border-[1px] border-[#D6DDEB] rounded-lg text-sm font-semibold text-white text-center bg-[#4261EE] hover:bg-[#D6DDEB]"
                    type="button"
                    @click="hideAlertPopup">
                    OK
                </button>
            </div>
        </AppPopupHeaderless>
        <AppPopupHeaderless v-model="showConfirmPopup" title="Confirmation" @buttonEvent="clickSubmit">
            {{ confirmMessage }}
        </AppPopupHeaderless>
    </Teleport>
</template>
<style scoped>
.box-style-border {
    border: 1px solid #d1d5db;
}
.transfer-grid {
    display: grid;
    grid-template-columns: 42% 3.5% 52%;
    gap: 1rem;
}
</style>
