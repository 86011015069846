<script>
import ElementsTooltipSingleton from '@/components/elements/ElementsTooltipSingleton.vue';

export default {
    components: {
        ElementsTooltipSingleton,
    },
    props: {
        statusCode: { type: String, default: 'ACTIVE' },
    },
    data() {
        return {
            employeeStatus: [
                { code: 'ACTIVE', color: '#34D399', tooltip: 'Active' },
                { code: 'ON_LEAVE', color: '#76A9FA', tooltip: 'Inactive' },
                { code: 'RESIGNED', color: '#9CA3AF', tooltip: 'Resigned' },
                { code: 'EXTENSION_REQUIRED', color: '#E3A008', tooltip: 'Extension Required' },
                { code: 'EXTENSION_PAST_DUE', color: '#A22FE8', tooltip: 'Extension Past Due' },
                { code: 'CONTRACT_INFO_REQUIRED', color: '#FF7777', tooltip: 'Contract info required' },
            ],
            selectedItem: null,
        };
    },
    watch: {
        statusCode(newVal) {
            if (!newVal) {
                return;
            }
            const self = this;
            self.initStatus();
        },
    },
    mounted() {
        const self = this;
        self.initStatus();
    },
    methods: {
        // -----------------------------------------------------------------------------
        loadTooltip(ele, msgFn, options) {
            this.__tooltipComponent__hidden__.loadBasic(ele, msgFn, options);
        },
        loadTooltipElementReady(ele) {
            if (ele == null) return; // auto-reload, so element can be null sometime.
            this.__tooltipComponent__hidden__ = ele;
        },
        // -----------------------------------------------------------------------------
        initStatus() {
            const self = this;

            self.employeeStatus.forEach((status) => {
                if (status.code === self.statusCode) {
                    self.selectedItem = status;
                }
            });
        },
    },
};
</script>

<template>
    <ElementsTooltipSingleton
        :ref="
            (el) => {
                loadTooltipElementReady(el);
            }
        " />
    <div
        v-if="selectedItem != null"
        class="employee-status-circle flex-shrink-0"
        :style="'background-color:' + selectedItem.color + ';'"
        :ref="
            (el) => {
                loadTooltip(el, () => {
                    return selectedItem.tooltip;
                });
            }
        "></div>
</template>
<style scoped>
.employee-status-circle {
    width: 10px;
    height: 10px;
    background-color: #34d399;
    border-radius: 50%;
    margin-left: 8px;
}
</style>
