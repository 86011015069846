<script>
import ElementsModal from '@/components/elements/ElementsModal.vue';
import ConfirmPopup from '@/components/ConfirmPopup.vue';

export default {
    components: {
        ElementsModal,
        ConfirmPopup,
    },
    props: {
        transfer: Boolean,
        deduct: Boolean,
    },
    computed: {},
    emits: ['clickTransferButton', 'clickDeductButton'],
    data() {
        return {
            availableWalletAmount: 0,

            totalAmount: 0,

            emailMessage: '',
            uploadFile: null,

            excelList: [],
            failedList: [],

            showFailedListPopup: false,
            showAlertPopup: false,
            alertPopupMessage: null,
            templateFileName: null,
        };
    },
    mounted() {
        const self = this;

        if (self.transfer) {
            self.templateFileName = 'transferTemplateFile.xlsx';
        }

        if (self.deduct) {
            self.templateFileName = 'deductTemplateFile.xlsx';
        }
        self.getAvailablePoint();
    },
    methods: {
        numberWithCommas(number) {
            if (!number) return;
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        getAvailablePoint() {
            const self = this;

            const url = self.$api('uri', 'get-availablePoint');
            self.$axios
                .get(url)
                .then((res) => {
                    self.availableWalletAmount = res.data.data.availableWalletAmount;
                })
                .catch((err) => {
                    console.error('err : ', err);
                });
        },
        clickDeleteButton(index) {
            const self = this;

            self.excelList.splice(index, 1);

            self.calTotalAmount();
        },
        clickTransferButton() {
            const self = this;

            // total amount 가 가지고 있는 것보다 큰 경우
            if (self.totalAmount > self.availableWalletAmount) {
                self.showAlertPopup = true;
                self.alertPopupMessage = 'The amount of transfer is more than total';
                return;
            }

            //mileageVolumeFormat 제거
            let transfer = self.excelList.map((emp) => {
                // eslint-disable-next-line no-unused-vars
                const { mileageVolumeFormat, ...other } = emp;
                return other;
            });

            if (self.checkValidation() == 0) {
                self.$emit('clickTransferButton', transfer, self.emailMessage);
            }
        },
        clickDeductButton() {
            const self = this;

            // total amount 가 가지고 있는 것보다 큰 경우
            if (self.totalAmount > self.availableWalletAmount) {
                self.showAlertPopup = true;
                self.alertPopupMessage = 'The amount of transfer is more than total';
                return;
            }

            //mileageVolumeFormat 제거
            let deduct = self.excelList.map((emp) => {
                // eslint-disable-next-line no-unused-vars
                const { mileageVolumeFormat, ...other } = emp;
                return other;
            });

            if (self.checkValidation() == 0) {
                self.$emit('clickDeductButton', deduct, self.emailMessage);
            }
        },
        onInputMileage(event, employee) {
            const self = this;

            const input = event.target.value;
            let mileage = input.replace(/[^0-9]/g, '');

            employee.mileageVolume = mileage;

            self.numberWithCommas(mileage);

            employee.mileageVolumeFormat = self.numberWithCommas(mileage);

            self.calTotalAmount();
        },
        calTotalAmount() {
            const self = this;

            if (self.excelList.length == 0) return (self.totalAmount = 0);
            self.totalAmount = 0;

            self.excelList.map((employee) => {
                const points = Number(employee.mileageVolume);

                self.totalAmount += points;
            });
        },
        checkValidation() {
            const self = this;

            let validationCnt = 0;

            //validation ---------------------------------------------------------------------------------
            //중복 값 제거
            self.excelList = self.excelList.filter((character, idx) => {
                if (character.email == null || '') return;
                return self.excelList.findIndex((item) => item.email == character.email) === idx;
            });

            //mileageVolume 에 정수 외의 값을 입력했을 경우
            self.excelList.map((employee) => {
                const points = Number(employee.mileageVolume);
                if (Number.isNaN(points) || points < 1) {
                    self.showAlertPopup = true;
                    self.alertPopupMessage = 'Please enter a number greater than 1';
                    validationCnt++;
                }
            });

            if (validationCnt > 0) {
                return validationCnt;
            }

            //아무도 선택하지 않았을 경우
            if (self.excelList.length == 0) {
                self.showAlertPopup = true;
                self.alertPopupMessage = 'Please add employees to transfer points.';
                validationCnt++;
            }

            self.calTotalAmount();
            return validationCnt;
        },
        clickUploadButton(event) {
            const self = this;
            event.preventDefault();

            if (self.uploadFile == null) {
                alert('Please upload a file.');
                return;
            }

            const formProps = new FormData(self.$refs['fileUploadForm']);

            const url = self.$api('uri', 'post-transfer-execute-file');

            self.$axios
                .post(url, formProps, {
                    headers: {
                        'Content-Type': 'multipart/form-data;',
                    },
                })
                .then((res) => {
                    self.failedList = res.data.data.failedList;
                    if (self.failedList.length > 0) {
                        console.log('showFailedListPopup ? : ', self.showFailedListPopup);
                        self.showFailedListPopup = true;
                        return;
                    }

                    self.excelList = res.data.data.excelList;

                    if (self.excelList != null) {
                        self.excelList.forEach((employee) => {
                            if (employee.mileageVolume != null) {
                                employee.mileageVolume = employee.mileageVolume.replace(/[^0-9]/g, '');
                                employee.mileageVolumeFormat = self.numberWithCommas(employee.mileageVolume);
                            }
                        });
                    }
                    self.calTotalAmount();
                })
                .catch((err) => {
                    alert('Please check fail again. If the value is empty, upload is not possible.');

                    console.error('err : ', err);
                });
        },
        removeFile() {
            const self = this;
            self.uploadFile = null;
        },
        getFailListString() {
            const self = this;
            let result = "The failed data's email addresses are ";
            result += self.failedList.map((failed) => failed.employeeEmail).join(', ');
            return result;
        },
    },
};
</script>

<template>
    <div class="mt-5 rounded border w-full max-w-7xl bg-white shadow box-style-border">
        <div class="w-full Class Properties p-10">
            <div class="w-full h-[124px] bg-[#4640DE] rounded-lg text-center">
                <p class="pt-[15px] text-[#fff] text-[16px]">Available Points</p>
                <p class="text-[#fff] text-[48px]">{{ availableWalletAmount }}</p>
            </div>
        </div>
        <div class="w-full p-10">
            <div class="w-full h-[1px] bg-[#D9D9D9] relative">
                <div class="w-[160px] bg-[#fff] absolute text-center left-[50%] translate-x-[-50%] top-[-10px]">File Upload</div>
            </div>
        </div>
        <div class="flex justify-between items-center gap-5 flex-wrap p-5">
            <div class="flex justify-between items-center gap-5 grow">
                <!-- template 를 다운받을때 .png 형식으로 받아야함. 다운로드 될때는 xlsx 형식으로 됨  -->
                <a :href="require(`@/assets/file/transferSampleFile.xlsx.png`)" :download=templateFileName>
                    <ElementsButton text="Download Template File" :fitContent="true" :height12="true" />
                </a>
                <form @submit="clickUploadButton" ref="fileUploadForm" class="flex grow">
                    <ElementsInput v-model="uploadFile" class="grow" inputName="multipartFile" inputtype="file" placeholder="Select File" :full="true" />
                    <ElementsButton class="ml-5" text="Upload" :fitContent="true" :height12="true" inputtype="submit" />
                </form>
                <ElementsButton text="Remove" :fitContent="true" :height12="true" :backgroundRed="true" @click-event="removeFile" />
            </div>
        </div>

        <div class="w-full p-10 overflow-auto">
            <div class="min-w-full w-fit">
                <div class="w-full h-[1px] bg-[#D9D9D9] relative">
                    <div class="w-[147px] bg-[#fff] absolute text-center left-[50%] translate-x-[-50%] top-[-10px]">Transfer List</div>
                </div>
                <div class="w-full">
                    <div class="text-[14px] text-center mt-[80px]" v-if="excelList.length == 0">+Add employees to send points</div>
                </div>
                <div class="mt-[80px]" v-if="excelList.length != 0">
                    <template v-for="(employee, index) in excelList" v-bind:key="index">
                        <div class="flex justify-between items-center py-2.5 w-full" :class="{ 'bg-slate-50': index % 2 !== 0 }">
                            <div class="w-20 text-sm font-normal text-center truncate">
                                {{ index + 1 }}
                            </div>
                            <div class="flex items-center gap-2 w-56 px-4">
                                <div class="ml-3.5 flex-1 text-base font-bold">
                                    {{ employee.employeeName }}
                                </div>
                            </div>
                            <div class="flex-1 text-sm font-normal text-center truncate">{{ employee.departmentName }} / {{ employee.jobTitleName }}</div>
                            <div class="flex-1 text-sm font-normal text-center truncate pr-10">
                                <ElementsInput v-model="employee.mileageVolumeFormat" placeholder="0" :full="true" @input="onInputMileage($event, employee)" />
                            </div>
                            <div class="flex-1 text-sm font-normal text-center truncate">
                                <ElementsInput v-model="employee.transactionNote" placeholder="Remark" :full="true" />
                            </div>
                            <button class="w-[128px] mx-8 border rounded border-indigo-400 text-indigo-400 px-2.5 py-1.5 text-center" @click="clickDeleteButton(index)">Delete</button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="w-full p-10">
            <div class="w-full h-[1px] bg-[#D9D9D9] relative">
                <div class="w-[147px] bg-[#fff] absolute text-center left-[50%] translate-x-[-50%] top-[-10px]">Message</div>
            </div>
            <textarea
                v-model="emailMessage"
                placeholder="Message"
                class="mt-[50px] p-10 outline-0 w-full h-[114px] text-sm border rounded-lg bg-white transition-all duration-500 shadow shadow-gray-100" />
        </div>
        <div class="w-full p-10">
            <div class="w-full h-[1px] bg-[#D9D9D9] relative">
                <div class="w-[250px] bg-[#fff] absolute text-center left-[50%] translate-x-[-50%] top-[-10px]">Total Amount : {{ numberWithCommas(totalAmount) ?? 0 }} Point</div>
            </div>
            <button v-if="transfer" class="rounded mt-[50px] w-full text-center text-[#fff] h-[62px] leading-[62px] bg-[#4640DE]" @click="clickTransferButton">Transfer</button>
            <button v-if="deduct" class="rounded mt-[50px] w-full text-center text-[#fff] h-[62px] leading-[62px] bg-[#4640DE]" @click="clickDeductButton">Deduct</button>
        </div>
        <Teleport to="body">
            <ElementsModal v-model="showFailedListPopup" buttonText="Close" :warn="true" @button-event="showFailedListPopup = false">
                <div class="mt-3 text-center sm:mt-5 w-96">
                    <div class="mt-2">
                        <p class="text-sm text-gray-500">
                            There are mismatched data in the template.<br />
                            Please ensure that these rows are checked.
                        </p>
                        <p class="text-xs whitespace-normal text-gray-500 mt-2">
                            {{ getFailListString() }}
                        </p>
                    </div>
                </div>
            </ElementsModal>
            <ConfirmPopup v-model="showAlertPopup" :confirmDescription="alertPopupMessage" buttonText="OK" />
        </Teleport>
    </div>
</template>
<style scoped>
.box-style-border {
    border: 1px solid #d1d5db;
}
</style>
