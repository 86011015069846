<script>
import { contractType } from '@/assets/data/optionData.js';
import moment from 'moment';

export default {
    props: {
        employeeData: Object,
    },
    data() {
        return {
            salaryData: {
                tinNo: '',
                bankName: '',
                bankAccountNumber: '',
                pagIbig: '',
                socialSecuritySystem: '',
                philhealth: '',
                rdoCode: '',
            },
            formattedTin: '',
            formattedSSS: '',
            formattedPhilhealth: '',
            formattedPagIbig: '',
        }; //return
    }, //data
    mounted() {
        const self = this;
        if (self.employeeData) {
            const { tinNo, bankName, bankAccountNumber, pagIbig, socialSecuritySystem, philhealth, rdoCode } = self.employeeData;
            self.salaryData = {
                tinNo: tinNo,
                bankName: bankName,
                bankAccountNumber: bankAccountNumber,
                pagIbig: pagIbig,
                socialSecuritySystem: socialSecuritySystem,
                philhealth: philhealth,
                rdoCode: rdoCode,
            };

            self.changeTinNumberFormat(tinNo);
            self.changeSSSNumberFormat(socialSecuritySystem);
            self.changePhilhealthFormat(philhealth);
            self.changePagIbigFormat(pagIbig);
        }
    },
    methods: {
        moment: moment,
        parseInt: parseInt,
        getSalaryData() {
            const self = this;
            return self.salaryData;
        },
        changeTinNumberFormat(input) {
            const self = this;

            if (!input) {
                return;
            }

            const inputValue = input?.replace(/\D/g, ''); // Remove non-digit characters
            let formattedValue = '';

            if (inputValue) {
                //
                formattedValue = inputValue.match(new RegExp('.{1,3}', 'g')).join('-'); // xxx-xxx-xxx
                const regex = /^(\d{3})(\d{3})(\d{3})(\d{1,})$/;
                const match = regex.exec(inputValue);

                if (match) {
                    formattedValue = `${match[1]}-${match[2]}-${match[3]}-${match[4]}`; // xxx-xxx-xxxxx
                    self.formattedTin = formattedValue;
                }

                self.formattedTin = formattedValue;
            }

            self.salaryData.tinNo = inputValue.replace(/\D/g, '');
        },
        changeSSSNumberFormat(input) {
            const self = this;

            if (!input) {
                return;
            }

            const inputValue = input?.replace(/\D/g, ''); // Remove non-digit characters
            let formattedValue = '';

            if (inputValue) {
                //
                const patternIndexArr = [2, 7];
                let patternValueArr = [];

                let value = inputValue;
                for (const pIndex of patternIndexArr) {
                    if (value.length > pIndex) {
                        let cut = value.substr(0, pIndex);
                        value = value.substr(pIndex);
                        patternValueArr.push(cut);
                    } else {
                        break;
                    }
                }

                if (value) {
                    patternValueArr.push(value);
                }

                formattedValue = patternValueArr.join('-');

                self.formattedSSS = formattedValue;
            }

            self.salaryData.socialSecuritySystem = inputValue.replace(/\D/g, '');
        },
        changePhilhealthFormat(input) {
            const self = this;

            if (!input) {
                return;
            }

            const inputValue = input?.replace(/\D/g, ''); // Remove non-digit characters
            let formattedValue = '';

            if (inputValue) {
                //
                const patternIndexArr = [2, 9];
                let patternValueArr = [];

                let value = inputValue;
                for (const pIndex of patternIndexArr) {
                    if (value.length > pIndex) {
                        let cut = value.substr(0, pIndex);
                        value = value.substr(pIndex);
                        patternValueArr.push(cut);
                    } else {
                        break;
                    }
                }

                if (value) {
                    patternValueArr.push(value);
                }

                formattedValue = patternValueArr.join('-');

                self.formattedPhilhealth = formattedValue;
            }

            self.salaryData.philhealth = inputValue.replace(/\D/g, '');
        },
        changePagIbigFormat(input) {
            const self = this;

            if (!input) {
                return;
            }

            const inputValue = input?.replace(/\D/g, ''); // Remove non-digit characters
            let formattedValue = '';

            if (inputValue) {
                //
                const patternIndexArr = [4, 4];
                let patternValueArr = [];

                let value = inputValue;
                for (const pIndex of patternIndexArr) {
                    if (value.length > pIndex) {
                        let cut = value.substr(0, pIndex);
                        value = value.substr(pIndex);
                        patternValueArr.push(cut);
                    } else {
                        break;
                    }
                }

                if (value) {
                    patternValueArr.push(value);
                }

                formattedValue = patternValueArr.join('-');

                self.formattedPagIbig = formattedValue;
            }

            self.salaryData.pagIbig = inputValue.replace(/\D/g, '');
        },
    },
};
</script>

<template>
    <div class="pr-7 pb-8 pl-7">
        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Tin Number</div>
            <div class="flex-1">
                <ElementsInput v-model="formattedTin" placeholder="000-000-000-000" class="flex-1" :full="true" :maxlength="15" @input="changeTinNumberFormat($event.target.value)" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">SSS No.</div>
            <div class="flex-1">
                <ElementsInput v-model="formattedSSS" placeholder="00-0000000-0" class="flex-1" :full="true" :maxlength="12" @input="changeSSSNumberFormat($event.target.value)" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">PHIL-HEALTH No.</div>
            <div class="flex-1">
                <ElementsInput v-model="formattedPhilhealth" placeholder="00-000000000-0" class="flex-1" :full="true" :maxlength="14" @input="changePhilhealthFormat($event.target.value)" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">PAG-IBIG No.</div>
            <div class="flex-1">
                <ElementsInput v-model="formattedPagIbig" placeholder="0000-0000-0000" class="flex-1" :full="true" :maxlength="14" @input="changePagIbigFormat($event.target.value)" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">RDO Code</div>
            <div class="flex-1">
                <ElementsInput v-model="salaryData.rdoCode" placeholder="Enter the RDO Code" class="flex-1" :full="true" :maxlength="3" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Bank Name</div>
            <div class="flex-1">
                <ElementsInput v-model="salaryData.bankName" placeholder="Enter the Bank Name" class="flex-1" :full="true" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Bank Account Number</div>
            <div class="flex-1">
                <ElementsInput v-model="salaryData.bankAccountNumber" placeholder="Enter the Bank Account Number" class="flex-1" :full="true" />
            </div>
        </div>
    </div>
</template>
<style scoped>
.popup_contents {
    width: calc(100% - 310px);
}

.add_popup {
    position: absolute;
    top: 0px;
}

select {
    appearance: none;
    background: url('@/assets/img/downArrow.svg') no-repeat right 16px center;
}

select option[value=''][disabled] {
    display: none;
}

input[type='date'] {
    position: relative;
    padding-right: 50px;
    background: url('@/assets/img/date_2.svg') no-repeat right 20px center;
}
input[type='date']::-webkit-clear-button,
input[type='date']::-webkit-inner-spin-button {
    display: none;
}
input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    color: transparent;
    cursor: pointer;
}
input[type='date'] {
    position: relative;
}
input[type='date']::before {
    content: attr(data-placeholder);
    width: 100%;
    color: #bbbbbb;
}
input[type='date']:valid::before {
    display: none;
}

.meal_expenses {
    /* border: 1px solid black; */
    border-collapse: collapse;
}

.meal_expenses tr td {
    border-bottom: 1px solid #ebeaed;
}

.meal_expenses tr:nth-child(1) td {
    border-bottom: 1px solid #4031b8;
}

.meal_expenses tr:nth-last-child(1) td {
    border: none;
}
</style>
