<script>
import ElementsButton from "@/components/elements/ElementsButton.vue";
import ElementsSelectRef from "@/components/elements/ElementsSelectRef.vue";
import ElementsInput from "@/components/elements/ElementsInput.vue";
import {ValidateUtil} from "@/plugins/app-util.js";
import AppPopup from '@/components/AppPopup'
import AppPopupHeaderless from '@/components/AppPopupHeaderless'

export default {
    components: {
        ElementsSelectRef,
        ElementsInput,
        ElementsButton,
        AppPopup,
        AppPopupHeaderless,
    },
    props: {
        modelValue: Boolean,
    },
    emits: ['update:modelValue'],
    mounted() {
        const self = this;
        self.initAsk();
        self.getComponyData();
    },
    data() {
        return {
            showAskPopup : false,
            showSuccessPopup : false,
            typeAskOptions: [
                {
                    text: "Top up",
                    value: "topUp",
                },
            ],

            topupSum: 0,
            phoneNumberPrepend: "+63",

            availableTime: {hour: "09", minute: "00"},
            sendAskData: {
                companyName: "",
            },
            sendAskDataErrMsg: {
                askContent: ''
            },

            minuitOptions: Array.from(Array(6).keys()).map(min => ({
                text: min.toString().padEnd(2, '0'),
                value: min.toString().padEnd(2, '0')
            })),
            hourOptions: Array.from(Array(23).keys()).map(hour => ({
                text: (hour + 1).toString().padStart(2, '0'),
                value: (hour + 1).toString().padStart(2, '0')
            })),
        }
    },
    watch : {
        modelValue( newVal ){
            const self = this;
            if (newVal) {
                self.initAsk();
                self.showAskPopup = true;
            } else {
                self.showAskPopup = false;
            }
        },

    },
    methods: {
        hidePopup() {
            const self = this;
            // self.showAskPopup = false;
            self.$emit('update:modelValue', false);
        },
        hideSuccessPopup() {
            const self = this;
            self.showSuccessPopup = false;
        },
        getComponyData(){
            const self = this;
            const url = self.$api("uri", "get-company")
            self.$axios
                .get(url, {})
                .then(function (res) {
                    const companyData = res.data.data;
                    self.sendAskData.companyName = companyData.companyName;
                })
                .catch( error => {
                    console.error("when getData error => ",error);
                })
        },
        initAsk() {
            const self = this;

            //init
            self.sendAskData = {
                ...self.sendAskData,
                userName: "",
                phoneNumber: "",
                inquiryType: "topUp",
                inquiryContent: ""
            };
            self.sendAskDataErrMsg = {...self.sendAskData, inquiryType: ""};
        },
        checkValidation() {
            const self = this;
            let validationCnt = 0;

            //init
            self.sendAskDataErrMsg = {
                userName: "",
                phoneNumber: "",
                availableTimeToContact: "",
                inquiryType: "",
                inquiryContent: ""
            };

            if (ValidateUtil.checkIsEmpty(self.sendAskData.userName)) {
                self.sendAskDataErrMsg.userName = "Name can't be blank.";
                validationCnt++;
            }
            if (!ValidateUtil.checkPhone(self.sendAskData.phoneNumber)) {
                self.sendAskDataErrMsg.phoneNumber = "Please enter a valid phone number.";
                validationCnt++;
            }
            if (ValidateUtil.checkIsEmpty(self.sendAskData.inquiryType)) {
                self.sendAskDataErrMsg.inquiryType = "*Inquiry type can't be blank.";
                validationCnt++;
            }
            if (ValidateUtil.checkIsEmpty(self.sendAskData.inquiryContent)) {
                self.sendAskDataErrMsg.inquiryContent = "Inquiry content can't be blank.";
                validationCnt++;
            }

            return validationCnt;
        },
        sendAsk() {
            const self = this;

            //validation 통과 했을때
            if (self.checkValidation() == 0) {
                const url = self.$api('uri', 'post-topup-ask-email');
                const {hour, minute} = self.availableTime;
                self.sendAskData.availableTimeToContact = `${hour}:${minute}`;
                self.$axios.post(url, self.sendAskData)
                .then((res) => {
                    self.showSuccessPopup = true;
                    self.hidePopup();
                })
                .catch((err) => {
                    console.error('err ? : ', err);
                })
            }


        },
    }
}
</script>

<template>
    <AppPopup
        v-model="showAskPopup"
        name="Contact to HRnFLEX"
        @afterClose="hidePopup"
        >
        <div class="pr-7 pb-7 pl-2.5 w-[644px]">
            <div class="flex items-center gap-2 mt-6">
                <div
                    class="w-[15%] text-right text-sm font-semibold text-slate-800"
                >
                    Company
                </div>
                <ElementsInput
                    v-model="sendAskData.companyName"
                    :readonly="true"
                    class="flex-1"
                    :full="true"
                />
            </div>
            <div class="flex items-center gap-2 mt-6">
                <div
                    class="w-[15%] text-right text-[14px] font-semibold text-slate-800"
                >
                    Name
                </div>
                <ElementsInput
                    v-model.trim="sendAskData.userName"
                    placeholder="Enter Full Name"
                    class="flex-1"
                    :error="sendAskDataErrMsg.userName"
                    :full="true"
                />
            </div>
            <div class="flex items-center gap-2 mt-6">
                <div
                    class="w-[15%] text-right text-[14px] font-semibold text-slate-800"
                >
                    Mobile number
                </div>
                <div class="flex-1 flex justify-between items-center gap-3.5">
                    <ElementsInput v-model="phoneNumberPrepend" :width14="true" :disabled="true"/>
                    <ElementsInput
                        v-model.trim="sendAskData.phoneNumber"
                        placeholder="Enter Phone Number"
                        class="flex-1"
                        :error="sendAskDataErrMsg.phoneNumber"
                        :full="true"
                    />
                </div>
            </div>
            <div class="flex items-center gap-2 mt-6">
                <div class="w-[15%] text-right text-[14px] font-semibold text-slate-800">
                    Available time for call
                </div>
                <div class="w-[85%] flex items-center gap-2">

                    <ElementsSelectRef
                        :width24="true"
                        :placeholder="'HH'"
                        :options="hourOptions"
                        v-model="availableTime.hour"
                    />
                    <ElementsSelectRef
                        :width24="true"
                        :placeholder="'mm'"
                        v-model="availableTime.minute"
                        :options="minuitOptions"
                    />
                </div>
            </div>
            <div class="flex items-center gap-2 mt-6">
                <div
                    class="w-[15%] text-right text-[14px] font-semibold text-slate-800"
                >
                    Type of statement
                </div>
                <div class="flex-1 flex justify-between items-center gap-3.5">
                    <ElementsSelectRef
                        v-model="sendAskData.inquiryType"
                        placeholder="Select Ask Type"
                        class="flex-1"
                        :full="true"
                        :error="sendAskDataErrMsg.inquiryType"
                        :options="typeAskOptions"
                    />
                </div>
            </div>
            <div class="flex items-center gap-2 mt-6">
                <div
                    class="w-[15%] text-right text-[14px] font-semibold text-slate-800"
                >
                    Content
                </div>
                <div class="flex-1 flex items-center h-[161px]">
                <textarea
                    v-model.trim="sendAskData.inquiryContent"
                    class="outline-0 w-full h-full text-sm border rounded-lg py-1 px-3 bg-white shadow shadow-gray-100"
                    :class="{ 'border-red-500' : sendAskDataErrMsg.inquiryContent }"
                />
                </div>
                <div class="flex mt-1.5 text-xs font-normal text-red-500"
                     v-show="sendAskDataErrMsg.inquiryContent">
                    *{{ sendAskDataErrMsg.inquiryContent }}
                </div>
            </div>
        </div>
        <div class="sticky bottom-0 ">
            <div class="w-full h-px bg-gray-200"></div>
            <div class="px-4 py-6 flex justify-end w-full bg-white">
                <ElementsButton
                    class="w-full"
                    text="Contact"
                    :widthFull="true"
                    @click="sendAsk"
                />
            </div>
        </div>
    </AppPopup>
    <AppPopupHeaderless
        v-model="showSuccessPopup"
        :hideTitle="true"
        min-width="32.75em"
        width="32.75em"
        height="13.5em"
    >
        <div class="flex flex-col justify-center items-center space-y-4 p-5">
            <div class="p-6 space-y-6 text-center text-[20px] text-[#504F54]">Your top-up request has been successfully completed!</div>
            <button
                class="h-[3.125rem] w-[10rem] border-[1px] border-[#D6DDEB] rounded-lg text-sm font-semibold text-white text-center bg-[#4261EE] hover:bg-[#D6DDEB]"
                type="button"
                @click="hideSuccessPopup">
                OK
            </button>
        </div>
    </AppPopupHeaderless>
</template>
<style scoped>
.box-style-border {
    border: 1px solid #D1D5DB;
}

</style>