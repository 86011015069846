<script>
import moment from 'moment';
import AppPopupHeaderless from '@/components/AppPopupHeaderless';
import ElementsButton from '@/components/elements/ElementsButton.vue';
import ElementsDatePicker from '@/components/elements/ElementsDatePicker.vue';

export default {
    components: {
        AppPopupHeaderless,
        ElementsButton,
        ElementsDatePicker,
    },
    props: {
        startDate: String,
        endDate: String,
        firstSearchType: {
            type: String,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: 'WEEKLY',
        },
    },
    emits: ['update:startDate', 'update:endDate', 'searchEvent'],
    data() {
        return {
            searchDateType: 'WEEKLY',
            searchDateText: null,
            startDatePicker: null,
            endDatePicker: null,
            weekDay: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            periodStart: moment(),
            periodEnd: moment(),
            showInfoPopup: false,
            infoPopupText: null,
            weekOffset: 0,
            monthOffset: 0,
        };
    },
    computed: {},
    watch: {},
    methods: {
        getSearchDate(start, end) {
            const self = this;

            return `${start.format('MM/DD/YYYY')}(${self.weekDay[start.day()]}) - ${end.format('MM/DD/YYYY')}(${self.weekDay[end.day()]})`;
        },
        getPeriodOfWeek(addWeekNum = 0) {
            const self = this;

            self.periodStart = moment();

            self.periodStart.add(addWeekNum, 'w');

            let clonePeriodStart = self.periodStart.clone();
            let clonePeriodStart2 = self.periodStart.clone();

            self.periodStart = clonePeriodStart.startOf('isoWeek');
            self.periodEnd = clonePeriodStart2.endOf('isoWeek');

            self.searchDateText = self.getSearchDate(self.periodStart, self.periodEnd);

            const start = self.periodStart.format('YYYYMMDD');
            const end = self.periodEnd.format('YYYYMMDD');

            self.$emit('update:startDate', start);
            self.$emit('update:endDate', end);
            self.$emit('searchEvent');
        },
        getPeriodOfMonth(addMonthNum = 0) {
            const self = this;

            self.periodStart = moment();

            self.periodStart.add(addMonthNum, 'M').date(1);
            let clonePeriodStart = self.periodStart.clone();
            self.periodEnd = clonePeriodStart.endOf('month');

            self.searchDateText = self.getSearchDate(self.periodStart, self.periodEnd);

            const start = self.periodStart.format('YYYYMMDD');
            const end = self.periodEnd.format('YYYYMMDD');

            self.$emit('update:startDate', start);
            self.$emit('update:endDate', end);
            self.$emit('searchEvent');
        },
        getPeriodOfManual() {
            // const self = this;
            // self.searchDateText = null;
        },
        changeSearchDateType(type) {
            const self = this;
            self.searchDateType = type;

            if (type === 'WEEKLY') {
                self.weekOffset = 0;
                self.getPeriodOfWeek();
            } else if (type === 'MONTHLY') {
                self.monthOffset = 0;
                self.getPeriodOfMonth();
            } else if (type === 'MANUAL') {
                self.getPeriodOfManual();
            }
        },
        changeSearchDate(addNumber) {
            const self = this;

            if (self.searchDateType === 'WEEKLY') {
                self.weekOffset += addNumber;
                self.getPeriodOfWeek(self.weekOffset);
            } else if (self.searchDateType === 'MONTHLY') {
                self.monthOffset += addNumber;
                self.getPeriodOfMonth(self.monthOffset);
            }
        },
        selectDatePicker() {
            const self = this;

            if (!self.startDatePicker || !self.endDatePicker) {
                return;
            }

            const start = moment(self.startDatePicker, 'YYYYMMDD');
            const end = moment(self.endDatePicker, 'YYYYMMDD');

            if (end.diff(start) < 0) {
                const message = 'The start date must be earlier than the end date.';
                self.showAndCloseInfoPopup(true, message);
                return;
            }

            const diffInYears = end.diff(start, 'years', true);

            if (diffInYears > 1) {
                const message = 'You can select a date range of up to 1 year.';
                self.showAndCloseInfoPopup(true, message);
                return;
            }

            self.searchDateText = self.getSearchDate(start, end);

            self.$emit('update:startDate', self.startDatePicker);
            self.$emit('update:endDate', self.endDatePicker);
            self.$emit('searchEvent');
        },
        showAndCloseInfoPopup(flag, text) {
            const self = this;

            self.infoPopupText = text;
            self.showInfoPopup = flag;
        },
    },
    mounted() {
        const self = this;

        self.changeSearchDateType(self.firstSearchType);
    },
};
</script>

<template>
    <div class="relative">
        <div class="flex justify-center items-center py-1">
            <div class="border rounded-lg bg-white flex justify-between shadow shadow-gray-100 w-[28em] h-[3.625em]">
                <template v-if="searchDateType == 'MANUAL'">
                    <a href="javascript:void(0)" class="w-14 flex justify-center items-center bg-gray-100 transition-all duration-500">
                        <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg" class="rotate-90">
                            <path d="M0 0L7 7L14 0H0Z" fill="#C8C8C8" />
                        </svg>
                    </a>
                </template>
                <template v-else>
                    <a href="javascript:void(0)" class="w-14 flex justify-center items-center hover:bg-gray-100 transition-all duration-500" @click="changeSearchDate(-1)">
                        <img src="@/assets/img/elements-select-1.svg" alt="elements-select-1" class="rotate-90" />
                    </a>
                </template>
                <div class="flex-1 border-x flex justify-center items-center text-center">
                    {{ searchDateText }}
                </div>
                <template v-if="searchDateType == 'MANUAL'">
                    <a href="javascript:void(0)" class="w-14 flex justify-center items-center bg-gray-100 transition-all duration-500">
                        <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg" class="-rotate-90">
                            <path d="M0 0L7 7L14 0H0Z" fill="#C8C8C8" />
                        </svg>
                    </a>
                </template>
                <template v-else>
                    <a href="javascript:void(0)" class="w-14 flex justify-center items-center hover:bg-gray-100 transition-all duration-500" @click="changeSearchDate(1)">
                        <img src="@/assets/img/elements-select-1.svg" alt="elements-select-1" class="-rotate-90" />
                    </a>
                </template>
            </div>
        </div>

        <div class="flex justify-center items-center py-1">
            <div class="flex w-[378px] h-[50px] border-gray-300 border rounded-lg bg-white grid grid-cols-3 mt-1">
                <button
                    class="h-full text-[#84818A] leading-[2.5rem] border-gray-300 border-r text-center rounded-l-lg hover:bg-[#E8EBFA]"
                    :class="{ 'selected-button': searchDateType == 'WEEKLY' }"
                    @click="changeSearchDateType('WEEKLY')">
                    Weekly
                </button>
                <button
                    class="h-full text-[#84818A] leading-[2.5rem] border-gray-300 border-r text-center hover:bg-[#E8EBFA]"
                    :class="{ 'selected-button': searchDateType == 'MONTHLY' }"
                    @click="changeSearchDateType('MONTHLY')">
                    Monthly
                </button>
                <button
                    class="h-full text-[#84818A] leading-[2.5rem] text-center rounded-r-lg hover:bg-[#E8EBFA]"
                    :class="{ 'selected-button': searchDateType == 'MANUAL' }"
                    @click="changeSearchDateType('MANUAL')">
                    Manual Select
                </button>
            </div>
        </div>

        <div class="flex justify-center items-center py-1">
            <div class="grid gap-5">
                <div v-if="searchDateType == 'MANUAL'" class="grid grid-cols-2 gap-2 items-center">
                    <ElementsDatePicker v-model="startDatePicker" placeholder="Start Date" @changeDate="selectDatePicker" />
                    <ElementsDatePicker v-model="endDatePicker" placeholder="End Date" inputFormat="MM-dd-yyyy" @changeDate="selectDatePicker" />
                </div>
            </div>
        </div>
    </div>
    <Teleport to="body">
        <AppPopupHeaderless v-model="showInfoPopup" :hideTitle="true" min-width="32.75em" width="32.75em" height="12.5em">
            <div class="flex flex-col justify-center items-center space-y-4 p-5">
                <div class="p-6 space-y-6 text-center text-[20px] text-[#504F54]">{{ infoPopupText }}</div>
                <button
                    class="h-[3.125rem] w-[10rem] border-[1px] border-[#D6DDEB] rounded-lg text-sm font-semibold text-white text-center bg-[#4261EE] hover:bg-[#D6DDEB]"
                    type="button"
                    @click="showAndCloseInfoPopup(false)">
                    OK
                </button>
            </div>
        </AppPopupHeaderless>
    </Teleport>
</template>
<style scoped>
.selected-button {
    background: #4261ee;
    color: #fff;
}
</style>
