const SETTING_MODE = Object.freeze({
    READ : "READ",
    ADD : "ADD",
    EDIT : "EDIT"
});

const DISTRIBUTION_BASE = Object.freeze({
    AMOUNT : "AMOUNT",
    TIER : "JOB_TITLE",
    TEAM : "DEPARTMENT"
});

const PAYROLL_FREQUENCY = Object.freeze({
    MONTHLY : "MONTHLY",
    SEMI_MONTHLY : "SEMI_MONTHLY",
    WEEKLY : "WEEKLY"
});

const PAYROLL_EMPLOYMENT_TYPE = Object.freeze({
    FULL_TIME : "FULL_TIME",
    PROBATION : "PROBATION",
    FIXED_TERM_CONTRACT : "FIXED_TERM_CONTRACT",
    PROJECT_BASE : "PROJECT_BASE",
    OUTSOURCE : "OUTSOURCE",
    TEMPORARY : "TEMPORARY"
});

const PAYROLL_PAYMENT_ON = Object.freeze({
    FIRST : "1ST",
    SECOND : "2ND",
    THIRD : "3RD",
    FOURTH : "4TH",
    NONE : "NONE"
});

export {
    SETTING_MODE,
    DISTRIBUTION_BASE,
    PAYROLL_FREQUENCY,
    PAYROLL_EMPLOYMENT_TYPE,
    PAYROLL_PAYMENT_ON
};