<script>
import { contractType } from '@/assets/data/optionData.js';
import moment from 'moment';

export default {
    props: {
        salaryInfo: Object,
        employeeData: Object,
        modelValue: Boolean,
    },
    data() {
        return {
            joinDate: '',
            employmentType: '',
            salaryData: {
                salaryAmountType: '',
                salaryAmount: null,
                taxCode: '',
                taxFilePath: '',
                taxFileName: '',
                peraYear: '',
                peraType: '',
                peraAmount: '',
                peraCertificateFilePath: '',
                peraCertificateFileName: '',
                otherDetails: '',
                effectiveDate: null,
                minEffectiveDate: null,
            },
            taxFileName: '',
            peraCertificateFileName: '',
            salaryAmountTypeOptions: [
                { text: 'Annually', value: 'ANNUALLY' },
                { text: 'Monthly', value: 'MONTHLY' },
                { text: 'Weekly', value: 'WEEKLY' },
                // {"text": "Daily", "value" : "DAILY"},
                // {"text": "Hourly", "value" : "HOURLY"},
            ],
            taxCodeOptions: [
                { text: 'S', value: 'S' },
                { text: 'S1', value: 'S1' },
                { text: 'S2', value: 'S2' },
                { text: 'S3', value: 'S3' },
                { text: 'S4', value: 'S4' },
                { text: 'M', value: 'M' },
                { text: 'M1', value: 'M1' },
                { text: 'M2', value: 'M2' },
                { text: 'M3', value: 'M3' },
                { text: 'M4', value: 'M4' },
                { text: 'Z', value: 'Z' },
            ],
            peraTypeOptions: [
                { text: 'United Investment Trust Funds', value: 'UNITED_INVESTMENT_TRUST_FUNDS' },
                { text: 'Mutual Funds', value: 'MUTUAL_FUNDS' },
                { text: 'Stocks From PSE-Listed Companies', value: 'STOCKS_FROM_PSE_LISTED_COMPANIES' },
                { text: 'Government Securities', value: 'GOVERNMENT_SECURITIES' },
                { text: 'Insurance Pension Products', value: 'INSURANCE_PENSION_PRODUCTS' },
                { text: 'Annuity Contracts', value: 'ANNUITY_CONTRACTS' },
                { text: 'Other Investment Products Authorized For PERA Purposes', value: 'OTHER' },
            ],
            minDateForEffectiveDate: null,
            minDateDisplayString: null,
        }; //return
    }, //data
    watch: {
        modelValue(newVal) {
            if (!newVal) {
                return;
            }
            const self = this;

            // 이 팝업이 실행될 때 마다 실행되어야 하는 작업
            // 마지막 payroll 다음날 부터 설정 가능하며 payroll이 없다면 join date 부터 설정 가능
            const url = self.$api('uri', 'get-employee-salary-last-payroll-cut-off').replace('{employeeSeq}', self.employeeData.employeeSeq);
            self.$axios
                .get(url)
                .then((res) => {
                    let minDate;
                    if (res.data.data != null && res.data.data.cutOffToDatetime != null) {
                        minDate = moment(res.data.data.cutOffToDatetime).add(1, 'days').toDate();
                    } else {
                        minDate = moment(parseInt(self.employeeData.joinDate), 'YYYYMMDD').toDate();
                    }
                    self.minDateForEffectiveDate = minDate;
                    self.minDateDisplayString = moment(minDate).format('MM/DD/YYYY');
                    self.salaryData.minEffectiveDate = moment(minDate).format('YYYYMMDD');
                })
                .catch((err) => {
                    console.log(err);
                });

            if (self.salaryInfo) {
                let effectiveDate = moment(self.salaryInfo.effectiveDate).format('YYYYMMDD');
                self.salaryData = { ...self.salaryInfo, effectiveDate };
            }
        },
    },
    mounted() {
        const self = this;
        const employeeData = self.employeeData;

        if (employeeData) {
            self.joinDate = employeeData.joinDate;
            const employmentType = employeeData.employmentType;

            for (const contract of contractType) {
                if (contract.value == employmentType) {
                    self.employmentType = contract.text;
                }
            }
        }
    },
    methods: {
        moment: moment,
        parseInt: parseInt,
        getSalaryData() {
            const self = this;
            return self.salaryData;
        },
        handleFileChange(e, target) {
            const self = this;

            if (e.target.files[0] == null) {
                return;
            }

            const filePath = e.target.files[0];
            const fileName = e.target.files[0].name;

            self.submitFileUpload(target, filePath, fileName);
        },
        submitFileUpload(target, filePath, fileName) {
            const self = this;
            const url = self.$api('uri', 'post-file-direct-upload');

            let form = new FormData();
            form.append(`uploadFile1`, filePath);
            form.append(`uploadFileName`, fileName);

            if (filePath === null) {
                return;
            }

            self.$axios
                .post(url, form, { headers: { 'Content-Type': 'multipart/form-data;' } })
                .then((res) => {
                    if (target == 'TAX_FILE_PATH') {
                        self.salaryData.taxFilePath = res.data.data.uploadFile1;
                        self.salaryData.taxFileName = fileName;
                    } else if (target == 'PERA_CERTIFICATE_FILE_PATH') {
                        self.salaryData.peraCertificateFilePath = res.data.data.uploadFile1;
                        self.salaryData.peraCertificateFileName = fileName;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    alert(' submit File Upload fail ');
                });
        },
        deleteFile(target) {
            const self = this;

            let filePath = null;
            let doNotDelete = false;

            if (target == 'TAX_FILE_PATH') {
                filePath = self.salaryData.taxFilePath;
                doNotDelete = self.salaryInfo.taxFilePath == filePath; // 이전에 가지고 있는거랑 같으면 삭제 안함
            } else if (target == 'PERA_CERTIFICATE_FILE_PATH') {
                filePath = self.salaryData.peraCertificateFilePath;
                doNotDelete = self.salaryInfo.peraCertificateFilePath == filePath;
            }

            if (!filePath || doNotDelete) {
                self.deleteFilePathAndName(target);
                return;
            }

            self.$axios
                .delete(filePath)
                .then((res) => {
                    self.deleteFilePathAndName(target);
                    console.log(res);
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        deleteFilePathAndName(target) {
            const self = this;

            if (target == 'TAX_FILE_PATH') {
                self.salaryData.taxFilePath = '';
                self.salaryData.taxFileName = '';
            } else if (target == 'PERA_CERTIFICATE_FILE_PATH') {
                self.salaryData.peraCertificateFilePath = '';
                self.salaryData.peraCertificateFileName = '';
            }
        },
    },
};
</script>

<template>
    <div class="pr-7 pb-8 pl-7">
        <div class="flex justify-between items-center gap-2 mt-6 w-96">
            <div class="w-32 text-sm font-semibold text-slate-800">Join Date</div>
            <div class="flex-1">
                {{ moment(parseInt(joinDate), 'YYYYMMDD').format('MM/DD/YYYY') }}
            </div>
        </div>
        <div class="flex justify-between items-center gap-2 mt-6 w-96">
            <div class="w-32 text-sm font-semibold text-slate-800">Employment Type</div>
            <div class="flex-1">
                {{ employmentType }}
            </div>
        </div>
        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Amount<span class="text-[#EA3636]">*</span></div>
            <div class="flex-1">
                <ElementsSelectRef v-model="salaryData.salaryAmountType" placeholder="Salary Type" class="flex-1" :options="salaryAmountTypeOptions" :useDefaultMsg="true" />
                <ElementsInput v-model="salaryData.salaryAmount" placeholder="Enter the contract amount" class="flex-1" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Tax Code<span class="text-[#EA3636]">*</span></div>
            <div class="flex-1">
                <ElementsSelectRef v-model="salaryData.taxCode" placeholder="Tax Code" class="flex-1" :options="taxCodeOptions" :useDefaultMsg="true" />
                <template v-if="salaryData.taxFileName">
                    <div class="w-96 flex justify-between gap-3 pt-3">
                        <div class="truncate text-sm text-slate-800">{{ salaryData.taxFileName }}</div>
                        <div class="text-red-700 text-sm font-semibold" @click="deleteFile('TAX_FILE_PATH')">delete</div>
                    </div>
                </template>
                <template v-else>
                    <ElementsInput inputName="multipartFile" inputtype="file" :placeholder="'Select File'" @input="handleFileChange($event, 'TAX_FILE_PATH')" />
                    <div class="mt-2 px-2 flex items-start gap-2">
                        <img src="@/assets/img/tab-job-info-2.svg" alt="tab-job-info-2" />
                        <p class="text-xs font-medium text-gray-400">Only JPEG,PNG and PDF files with Max size of 5MB</p>
                    </div>
                </template>
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">PERA Info</div>
            <div class="flex-1">
                <ElementsInput v-model="salaryData.peraYear" placeholder="Input Year" :inputtype="'Number'" :minNumber="1900" :maxNumber="2100" />
                <ElementsSelectRef v-model="salaryData.peraType" placeholder="PERA Type" class="flex-1" :options="peraTypeOptions" :useDefaultMsg="true" />
                <ElementsInput v-model="salaryData.peraAmount" placeholder="Enter the PERA amount" :inputtype="'Number'" class="flex-1" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">PERA Certificate</div>
            <div class="flex-1">
                <template v-if="salaryData.peraCertificateFileName">
                    <div class="w-96 flex justify-between gap-3 pt-3">
                        <div class="truncate text-sm text-slate-800">{{ salaryData.peraCertificateFileName }}</div>
                        <div class="text-red-700 text-sm font-semibold" @click="deleteFile('PERA_CERTIFICATE_FILE_PATH')">delete</div>
                    </div>
                </template>
                <template v-else>
                    <ElementsInput class="grow" inputName="multipartFile" inputtype="file" placeholder="Select File" @input="handleFileChange($event, 'PERA_CERTIFICATE_FILE_PATH')" />
                    <div class="mt-2 px-2 flex items-start gap-2">
                        <img src="@/assets/img/tab-job-info-2.svg" alt="tab-job-info-2" />
                        <p class="text-xs font-medium text-gray-400">Only JPEG,PNG and PDF files with Max size of 5MB</p>
                    </div>
                </template>
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Other Details</div>
            <div class="flex-1">
                <textarea
                    placeholder="Please enter other contract details"
                    class="h-[153px] w-96 resize-none border-gray-300 border-[1px] rounded-[8px] py-[11px] px-[21px]"
                    v-model="salaryData.otherDetails"
                    :maxlength="500">
                </textarea>
            </div>
        </div>
        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Effective Date<span class="text-[#EA3636]">*</span></div>
            <div class="flex-1">
                <ElementsDatePicker v-model="salaryData.effectiveDate" class="__datepicker" placeholder="MM/DD/YYYY" :minDate="minDateForEffectiveDate"> </ElementsDatePicker>
                <p class="text-right text-xs text-red-600">You can set that starting from {{ minDateDisplayString }}</p>
            </div>
        </div>
    </div>
</template>
<style scoped>
.popup_contents {
    width: calc(100% - 310px);
}

.add_popup {
    position: absolute;
    top: 0px;
}

select {
    appearance: none;
    background: url('@/assets/img/downArrow.svg') no-repeat right 16px center;
}

select option[value=''][disabled] {
    display: none;
}

input[type='date'] {
    position: relative;
    padding-right: 50px;
    background: url('@/assets/img/date_2.svg') no-repeat right 20px center;
}
input[type='date']::-webkit-clear-button,
input[type='date']::-webkit-inner-spin-button {
    display: none;
}
input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    color: transparent;
    cursor: pointer;
}
input[type='date'] {
    position: relative;
}
input[type='date']::before {
    content: attr(data-placeholder);
    width: 100%;
    color: #bbbbbb;
}
input[type='date']:valid::before {
    display: none;
}

/* .__datepicker::v-deep() {

    .v3dp__popout {
        bottom: 0;
        right: 0;
    }
} */

.meal_expenses {
    /* border: 1px solid black; */
    border-collapse: collapse;
}

.meal_expenses tr td {
    border-bottom: 1px solid #ebeaed;
}

.meal_expenses tr:nth-child(1) td {
    border-bottom: 1px solid #4031b8;
}

.meal_expenses tr:nth-last-child(1) td {
    border: none;
}
</style>
