<script>
import PopupContactInfo from "@/pages/employees/employees/popups/PopupContactInfo.vue";
import PopupSalaryInfo from "@/pages/employees/employees/popups/PopupSalaryInfo.vue";
import PopupSalaryInfoPrevious from "@/pages/employees/employees/popups/PopupSalaryInfoPrevious.vue"
import TabJobInfo from "@/pages/employees/employees/popups/tabs/TabJobInfo.vue";
import TabBasicInfo from "@/pages/employees/employees/popups/tabs/TabBasicInfo.vue";
import TabEducation from "@/pages/employees/employees/popups/tabs/TabEducation.vue";
import TabCareer from "@/pages/employees/employees/popups/tabs/TabCareer.vue";
import TabDocument from "@/pages/employees/employees/popups/tabs/TabDocument";
import { ValidateUtil } from "@/plugins/app-util.js";
import { EmojiSadIcon } from "@heroicons/vue/outline";
import { status } from "@/assets/data/optionData.js"
import moment from "moment";

export default {
	components: {
        PopupContactInfo,
        PopupSalaryInfo,
		PopupSalaryInfoPrevious,
		TabJobInfo,
		TabBasicInfo,
		TabEducation,
		TabCareer,
        TabDocument,
		EmojiSadIcon,
	},
	props: {
		modelValue: {
			type: String,
		},
		selectedEmployeeSeq:String,
	},
    emits: ["update:modelValue"],
	data() {
		return {
            notGrayscale: 'grayscale(0%)',
            grayscale: 'grayscale(100%)',
            dateValue: null,
            dateFormat : 'YYYY-MM-DDTHH:mm:ss.sssZ',
            // divClassFlex : true,
            employeeStatusHistoryList : [],
            userSeq: null,
            employeeStatus: null,
            contractInfoEmployeeList: [],
            showDeleteCheckModal: false,
            employeeSeq: null,
            isShow: false,
            employeeStatusOptions: status,
			selectedTab: {},
            PopupContactInfo: false,
            PopupSalaryInfo: false,
            PopupProfileInfo: true,
			PopupSalaryInfoPrevious: false,
			tabs: [
				{
					name: "jobInfo",
					text: "Job Info",
					permissionKey : 'JOB_INFO',
					permission : false,
				},
				{
					name: "basicInfo",
					text: "Basic Info",
					permissionKey : 'BASIC_INFO',
					permission : false,
				},
				{
					name: "education",
					text: "Education",
					permissionKey : 'EDUCATION_INFO',
					permission : false,
				},
				{
					name: "career",
					text: "Work Experience/Certificate",
					permissionKey : 'CAREER_QUALIFI_INFO',
					permission : false,
				},
				{
					name: "document",
					text: "Employee Documents",
					permissionKey : 'EMPLOYEE_DOCUMENTS_INFO',
					permission : false,
				},
			],
			employee: {},
			noInfoPermission : false,

		}; //return
	}, //data
	computed: {
		// employee: {
		//     get(){ return this.modelValue},
		//     set(value){
		//         const self = this;
		//         self.$emit("update:modelValue", value)
		//     },
		// },
	},
	methods: {
        changeDate(date){
            const now = new Date();
            const formatter = new Intl.DateTimeFormat('fr-CA', { year: 'numeric', month: 'numeric', day: 'numeric' });
            const dateString = formatter.format(date).replace(/-/g, '');
            return dateString;
        },
        moment: moment,
        parseInt: parseInt,
        // 계약 정보가 30일 이하인 데이터 리스트를 불러온다
        getContractInfoEmployeeList(userSeq){
            const self = this;
            const url = self.$api("uri", `get-employee-contract-info-history-one`).replace("{employeeSeq}", encodeURIComponent(userSeq));
            self.$axios
                .get(url)
                .then((res) => {
                    self.contractInfoEmployeeList = res.data.data.list;
                
                    if(self.contractInfoEmployeeList.length != 0){
                        self.dateValue = self.contractInfoEmployeeList[0].employeeContractInfoStartDatetime;
                    }
                })
                .catch((err) => {
                    console.error('err : ',err);
                })
        },
        isWithin30Days(date) {
            const today = new Date();
            const diff = today.getTime() - new Date(date).getTime(); // date를 new Date()로 변환하여 차이를 계산
            const diffInDays = diff / (1000 * 60 * 60 * 24); // 밀리초를 일(day) 단위로 변환
            return diffInDays <= 30;
        },
        clickPermanentlyDeleteButton() {
            const self = this;
            self.showDeleteCheckModal = true;
        },
        clickStatus(){
            const self = this;
                if(self.isShow){
                    self.isShow = false;
                }else{
                    self.isShow = true;
                }
        },
        clickStatusDeleteButton(){
            const self = this;
            self.isShow = true;
        },
        showInfo(infoName){
            const self = this;
            self.PopupProfileInfo = false;
            self.PopupContactInfo = false;
            self.PopupSalaryInfo = false;
			self.PopupSalaryInfoPrevious = false;

            switch(infoName) {
                case 'PROFILE' :
                    self.PopupProfileInfo = true;
                    break;
                case 'CONTRACT' :
                    self.PopupContactInfo = true;
                    break;
                case 'SALARY' :
                    self.PopupSalaryInfo = true;
                    break;
				case 'SALARY_PREVIOUS' :
					self.PopupSalaryInfoPrevious = true;
					break;
                default :
                    self.PopupProfileInfo = true;
            }
        },
        showContactInfo(){
            const self = this;
            self.PopupProfileInfo = false;
            self.PopupContactInfo = true;

            if (self.grayscaleContract === 'grayscale(100%)') {
                self.grayscaleContract = 'grayscale(0%)';
                self.grayscaleProfile = 'grayscale(100%)';

            } else {
                self.grayscaleProfile = 'grayscale(100%)';
            }
        },
        showProfileInfo(){
            const self = this;
            self.PopupProfileInfo = true;
            self.PopupContactInfo = false;

            if (self.grayscaleProfile === 'grayscale(100%)') {
                self.grayscaleProfile = 'grayscale(0%)';
                self.grayscaleContract = 'grayscale(100%)';

            } else {
                self.grayscaleProfile = 'grayscale(100%)';
            }
        },
		over15Charaters: ValidateUtil.over15Charaters,
		over15CharFromFullName: ValidateUtil.over15CharFromFullName,
		clickTab(tab) {
			const self = this;
			if( self.noInfoPermission ) {
				return;
			}
			this.selectedTab = tab;
		},
		getEmployeeData() {
			const self = this;
			const url = self.$api("uri", `get-employee-basic-info`).replace("{employeeSeq}", encodeURIComponent(self.selectedEmployeeSeq));
			self.$axios.get(url)
				.then((res) => {
					self.employee = res.data.data;

                    self.userSeq = self.employee.employeeSeq;
		            self.getContractInfoEmployeeList(self.userSeq);
				})
				.catch((err) => {
					console.log(err);
				});
		},
        getEmployeeStatusHistoryDataAll(userSeq) {
			const self = this;
			const url = self.$api("uri", `get-employee-status-history-all`).replace("{employeeSeq}", encodeURIComponent(userSeq));
            self.$axios.get(url)
				.then((res) => {
					self.employeeStatusHistoryList = res.data.data.list;
                })
				.catch((err) => {
					console.log(err);
				});
		}
	},
	mounted(){
        const self =this;
		self.getEmployeeData();
		// self.getEmployeeStatusHistoryData();
        // self.getEmployeeStatusHistoryDataAll( self.selectedEmployeeSeq )
        
		self.getContractInfoEmployeeList(self.userSeq);

		const tab = this.tabs.find(tab => tab.permission);
		this.clickTab(tab);
	},
	beforeMount(){
		const self = this;

		const permission_menu = [
			"BASIC_INFO_EDIT",
			"JOB_INFO_EDIT",
			"EDUCATION_INFO_EDIT",
			"CAREER_QUALIFI_INFO_EDIT",
			"BASIC_INFO_VIEW",
			"JOB_INFO_VIEW",
			"EDUCATION_INFO_VIEW",
			"CAREER_QUALIFI_INFO_VIEW",
            "EMPLOYEE_DOCUMENTS_INFO_VIEW",
		]

		// 권한 있나 찾음 
		const infoPermissions = permission_menu.filter( self.$appUtil.checkPermission );
		self.noInfoPermission = infoPermissions.length == 0;
		// 권한 있으면 각 탭 볼수있는지 권한 넣기
		if ( ! self.noInfoPermission ) {
			self.tabs.forEach( tab => {
				const re = new RegExp(`${tab.permissionKey}.*`)
				tab.permission = infoPermissions.some( pms => re.test(pms) )
			})
		}
	}
};
</script>

<template>
	<div class="w-[70vw]">
        <!-- STATUS add modal -->
		<div class="flex">
			<div class="border-r border-gray-200">
				<div class="flex px-7 py-4 gap-4 items-center">
					<div
						class="border rounded-full w-24 h-24"
						v-if="employee.profilePhotoUrl"
					>
						<img
							class="border rounded-full object-cover h-24 w-24 object-center"
							:src="employee.profilePhotoUrl"
							alt="employees-information-1"
							width="100"
							height="100"
						/>
					</div>
					<div
						v-else
						class="rounded-full w-24 h-24 bg-rose-400 flex justify-center items-center"
					>
						<span
						v-if="employee.employeeName"
						class="text-5xl font-medium text-white"
						>{{ employee.employeeName.substring(0, 1).toUpperCase() }}</span
						>
					</div>
					<div>
						<h3 class="text-lg font-bold">
							{{ employee.employeeName }}
						</h3>
						<p class="text-sm font-normal w-32 truncate">
							{{ employee.departmentName }}
						</p>
                        <!-- ACTIVE, Inactive, Resigned -->
                            <div v-if="employee.employeeStatus=='ON_LEAVE'"
                                style="background-color: #D0E1F2; color: #3f85ca;"
                                class="mt-1 rounded px-6 py-1.5 text-center text-sm font-bold w-fit"
                                >
                                Inactive
                            </div>
                            <div v-else-if="employee.employeeStatus=='RESIGNED'"
                                style="background-color: #D8D8D8; color: #666565;"
                                class="mt-1 rounded px-6 py-1.5 text-center text-sm font-bold w-fit"
                                >
                                Resigned
                            </div>
                            <div v-else-if="employee.employeeStatus=='EXTENSION_REQUIRED'"
                                class="font-semibold bg-yellow-50 text-sm mr-2 px-6 py-1.5 rounded" style="color:#F3D268"
                                >
                                Extension <br> 
                                Required
                            </div>
                            <div v-else-if="employee.employeeStatus=='EXTENSION_PAST_DUE'"
                                    style="background-color: #F1B1BD; color:#F42053;"
                                    class="mt-1 rounded px-6 py-1.5 text-center text-sm font-bold w-fit"
                                    >
                                    Extension <br>
                                    Past Due
                            </div>
                            <div v-else-if="employee.employeeStatus=='CONTRACT_INFO_REQUIRED'"
                                    style="background-color: #F1B1BD; color:#F42053;"
                                    class="mt-1 rounded px-6 py-1.5 text-center text-sm font-bold w-fit"
                                    >
                                Contract Info <br>
                                Required
                            </div>
                            <div v-else
                                class="mt-1 rounded bg-emerald-400/20 text-emerald-400 px-6 py-1.5 text-center text-sm font-bold w-fit"
                                >
                                Active
                            </div>
                    </div>
				</div>
				<div class="w-full h-px bg-gray-200"></div>
				<div class="px-6 py-7">
					<a
                        @click="showInfo('PROFILE')"
						href="javascript:void(0)"
						class="rounded flex items-center gap-4 h-12 w-full text-base font-semibold px-3.5"
                        :class="{
								'bg-indigo-600/20': PopupProfileInfo,
						}"
                    >
						<img
							src="@/assets/img/popup-employees-information-1.svg"
							alt="popup-employees-information-1"
							class="w-6 h-6"
                            :style="{ filter: PopupProfileInfo ? notGrayscale : grayscale }"
						/>
						<span class="flex-1">Profile</span>
					</a>
                    <!-- 임시 주석  -->
					<!-- <a
						href="javascript:void(0)"
						class="mt-2 rounded flex items-center gap-4 h-12 w-full text-base font-semibold px-3.5"
					>
						<img
							src="@/assets/img/popup-employees-information-2.svg"
							alt="popup-employees-information-2"
							class="w-6 h-6"
						/>
						<span class="flex-1">Attendance History</span>
					</a>
					<a
						href="javascript:void(0)"
						class="mt-2 rounded flex items-center gap-4 h-12 w-full text-base font-semibold px-3.5"
					>
						<img
							src="@/assets/img/popup-employees-information-3.svg"
							alt="popup-employees-information-3"
							class="w-6 h-6"
						/>
						<span class="flex-1">Leave History</span>
					</a> -->
                    <a
                        @click="showInfo('CONTRACT')"
						href="javascript:void(0)"
						class="mt-2 rounded flex items-center gap-4 h-12 w-full text-base font-semibold px-3.5"
                        :class="{
								'bg-indigo-600/20': PopupContactInfo,
						}"
                    >
						<img
							src="@/assets/img/contract_on.png"
							alt="popup-employees-information-3"
							class="w-6 h-6"
                            :style="{ filter: PopupContactInfo ? notGrayscale : grayscale }"
						/>
						<span class="flex-1">Contract Info</span>
					</a>
                    <a
                        v-if="$appUtil.checkPermission('PAYROLL_SETTING_EDIT') && $appUtil.checkSubscription('PAYROLL_USAGE')"
                        @click="showInfo('SALARY')"
                        href="javascript:void(0)"
                        class="mt-2 rounded flex items-center gap-4 h-12 w-full text-base font-semibold px-3.5"
                        :class="{
								'bg-indigo-600/20': PopupSalaryInfo,
						}"
                    >
                        <img
                            src="@/assets/img/salary-info.svg"
                            alt="popup-employees-information-3"
                            class="w-6 h-6"
                            :style="{ filter: PopupSalaryInfo ? notGrayscale : grayscale }"
                        />
                        <span class="flex-1">Salary Info</span>
                    </a>
					<a
                        v-if="$appUtil.checkPermission('PAYROLL_SETTING_EDIT') && $appUtil.checkSubscription('PAYROLL_USAGE')"
                        @click="showInfo('SALARY_PREVIOUS')"
                        href="javascript:void(0)"
                        class="mt-2 rounded flex items-center gap-4 h-12 w-full text-base font-semibold px-3.5"
                        :class="{
								'bg-indigo-600/20': PopupSalaryInfoPrevious,
						}"
                    >
						<img
                            src="@/assets/img/elements-file-1.svg"
                            alt="popup-employees-information-3"
                            class="w-6 h-6"
                            :style="{ filter: PopupSalaryInfoPrevious ? notGrayscale : grayscale }"
                        />
                        <span class="flex-1">Previous Employer</span>
                    </a>
				</div>
			</div>
			<div v-if="PopupProfileInfo" class="flex-1 px-3 py-4">
				<h3 class="text-xl font-bold">Profile</h3>
				<div
					class="mt-3 border-b border-gray-300 bg-transparent flex justify-between items-center gap-14"
				>
					<template v-for="tab in tabs" v-bind:key="tab.name">
						<a
							v-show="tab.permission"
							class="border-b-4 pb-1.5 text-base font-semibold text-gray-500 transition-all duration-500 flex-1 text-center"
							:class="{
								'border-transparent': selectedTab !== tab,
								'border-indigo-600': selectedTab === tab,
								'text-black': selectedTab === tab,
							}"
							href="javascript:void(0)"
							@click="clickTab(tab)"
						>
							{{ tab.text }}
						</a>
					</template>
				</div>
				<template v-if="noInfoPermission">
					<div class="flex flex-col items-center my-20">
						<EmojiSadIcon class="h-10 w-10"/>
						<div class="text-gray-500 text-lg font-semibold" > Access Restricted </div>
						<div class="text-gray-500 "> 
							You don't have permission to view information of employee
						</div>
					</div>
				</template>
				<template v-else > 
					<div v-if="tabs[0] === selectedTab" class="overflow-auto h-[50vh] pb-2">
						<TabJobInfo 
                            @afterSave="getEmployeeData"
                            @getHistory="getEmployeeStatusHistoryDataAll(selectedEmployeeSeq)"
                            :employeeStatusHistoryList="employeeStatusHistoryList"
                            :selectedEmployeeSeq="selectedEmployeeSeq"
                        />
					</div>
					<div v-if="tabs[1] === selectedTab" class="overflow-auto h-[50vh] pb-2">
						<TabBasicInfo @afterSave="getEmployeeData" :selectedEmployeeSeq="selectedEmployeeSeq" />
					</div>
					<div v-if="tabs[2] === selectedTab" class="overflow-auto h-[50vh] pb-2">
						<TabEducation :selectedEmployeeSeq="selectedEmployeeSeq"/>
					</div>
					<div v-if="tabs[3] === selectedTab" class="overflow-auto h-[50vh] pb-2">
						<TabCareer :selectedEmployeeSeq="selectedEmployeeSeq"/>
					</div>
                    <div v-if="tabs[4] === selectedTab" class="overflow-auto h-[50vh] pb-2">
                        <TabDocument :selectedEmployeeSeq="selectedEmployeeSeq"/>
                    </div>
				</template>
			</div>
            <div v-if="PopupContactInfo" class="flex-1 px-3 py-4">
                <PopupContactInfo 
                :employeeData="employee"
				@afterSave="getEmployeeData"
				/>
            </div>
            <div v-if="PopupSalaryInfo" class="flex-1 px-3 py-4">
                <PopupSalaryInfo
                    :employeeData="employee"/>
            </div>
			<div v-if="PopupSalaryInfoPrevious" class="flex-1 px-3 py-4">
                <PopupSalaryInfoPrevious
                    :employeeData="employee"/>
            </div>
		</div>
	</div>
</template>
