<template>
    <div class="border w-[600px] bg-white border-[#EAEDF7] rounded-[10px]">
        <table class="divide-y divide-gray-300 w-full border-black border-opacity-10">
            <thead class="bg-[#F8F8FD] w-full">
                <tr class="bg-gray-200">
                    <th scope="col" class="px-2 py-3.5 text-center text-sm text-gray-900">Cut Off</th>
                    <th scope="col" class="px-2 py-3.5 text-center text-sm text-gray-900">Working Day</th>
                    <th scope="col" class="px-2 py-3.5 text-center text-sm text-gray-900">Basic Daily Wage</th>
                    <th scope="col" class="px-2 py-3.5 text-center text-sm text-gray-900">Basic Hourly Wage</th>
                    <th scope="col" class="px-2 py-3.5 text-center text-sm text-gray-900">Regular Basic</th>
                </tr>
            </thead>
            <tbody class=" bg-white table-fixed">
                <tr v-for="(detail, index) in basicSalaryDetailList" v-bind:key="index">
                    <td class="whitespace-normal px-3 py-4 text-sm text-center text-gray-900">{{ detail.startDate }} ~ {{ detail.endDate }}</td>
                    <td class="whitespace-normal px-3 py-4 text-sm text-center text-gray-900">{{ detail.workingDayCount }} days</td>
                    <td class="whitespace-normal px-3 py-4 text-sm text-center text-gray-900">{{ fitDecimalFormatWithComma(detail.dailyWageAmount, 5) }}</td>
                    <td class="whitespace-normal px-3 py-4 text-sm text-center text-gray-900">{{ fitDecimalFormatWithComma(detail.hourlyWageAmount, 5) }}</td>
                    <td class="whitespace-normal px-3 py-4 text-sm text-center text-gray-900">{{ fitDecimalFormatWithComma(detail.workingDayCount * detail.dailyWageAmount, 5) }}</td>
                </tr>
                <tr class="bg-gray-200">
                    <td colspan="3" class="whitespace-normal px-3 py-4 text-sm text-center text-gray-900">Total Regular Basic</td>
                    <td colspan="2" class="whitespace-normal px-3 py-4 text-sm text-center text-gray-900">{{ fitDecimalFormatWithComma(getTotalRegularBasic()) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { NumberFormatUtil, DateFormatUtil} from "@/plugins/app-util";

export default {
    components :{
    },
    emits : ['update:modelValue', 'afterClose'],
    props : {
        modelValue : Boolean,
        basicSalaryDetailList : Array
    },
    data(){
        return {
        }
    },
    mounted() {
    },
    methods : {
        hidePopup() {
            const self = this;
            self.$emit('update:modelValue', false);
            self.$emit('afterClose');
        },
        getTotalRegularBasic() {
            const self = this;
            let total = 0;
            self.basicSalaryDetailList.forEach(basicSalaryDetail => {
                total += basicSalaryDetail.workingDayCount * basicSalaryDetail.dailyWageAmount;
            });

            return total;
        },
        fitDecimalFormatWithComma : NumberFormatUtil.fitDecimalFormatWithComma,
        fitDateFormat : DateFormatUtil.fitDateFormat
    }
}
</script>