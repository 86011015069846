<template>
    <div class="pr-7 pb-8 pl-7">
        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Employer Name</div>
            <div class="flex-1">
                <ElementsInput v-model="employeePreviousSalaryData.employerName" placeholder="Enter the Employer Name" class="flex-1" :full="true" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Employer TIN</div>
            <div class="flex-1">
                <ElementsInput
                    v-model="formattedTin"
                    placeholder="Enter the Employer TIN(000-000-000-00000)"
                    class="flex-1 mt-3.5"
                    @input="changeTinNumberFormat($event.target.value)"
                    :full="true"
                    :maxlength="17" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Address</div>
            <div class="flex-1">
                <ElementsInput v-model="employeePreviousSalaryData.address" placeholder="Enter the Address" class="flex-1" :full="true" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">ZIP Code</div>
            <div class="flex-1">
                <ElementsInput v-model="employeePreviousSalaryData.zipCode" placeholder="Enter the ZIP Code" class="flex-1" :full="true" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Taxable Year</div>
            <div class="flex-1">
                <ElementsSelectRef v-model="employeePreviousSalaryData.taxableYear" :options="yearOptions" placeholder="Enter the Taxable Year" class="flex-1" :full="true" />
            </div>
        </div>
        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">From Date</div>
            <div class="flex-1">
                <ElementsDatePicker
                    v-model="employeePreviousSalaryData.taxableFrom"
                    :format="'MMDD'"
                    :textLeft="true"
                    :displayFormat="'MM/dd'"
                    class="flex-1"
                    :full="true"
                    :dayPickerHeadingFormat="'LLLL'" />
            </div>
        </div>
        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">To Date</div>
            <div class="flex-1">
                <ElementsDatePicker
                    v-model="employeePreviousSalaryData.taxableTo"
                    :format="'MMDD'"
                    :textLeft="true"
                    :displayFormat="'MM/dd'"
                    class="flex-1"
                    :full="true"
                    :dayPickerHeadingFormat="'LLLL'" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">NON-TAXABLE Basic Salary<br />(P250,000 And Below)</div>
            <div class="flex-1">
                <ElementsInput v-model="employeePreviousSalaryData.nonTaxableBasicSalary" placeholder="Enter the non taxable basic salary" class="flex-1" :full="true" :isNumber="true" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">NON-TAXABLE 13th Month & Other Benefits</div>
            <div class="flex-1">
                <ElementsInput
                    v-model="employeePreviousSalaryData.nonTaxable13thMonthAndOtherBenefits"
                    placeholder="Enter the non taxable 13th Month & Other Benefits"
                    class="flex-1"
                    :full="true"
                    :isNumber="true" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">NON-TAXABLE De Minimis Benefits</div>
            <div class="flex-1">
                <ElementsInput v-model="employeePreviousSalaryData.nonTaxableDeminimisBenefits" placeholder="Enter the To Date" class="flex-1" :full="true" :isNumber="true" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">NON-TAXABLE SSS, GSIS, PAG-IBIG<br />& Union Dues</div>
            <div class="flex-1">
                <ElementsInput v-model="employeePreviousSalaryData.nonTaxableSssGsisPagibigAndUnionDues" placeholder="Enter the To Date" class="flex-1" :full="true" :isNumber="true" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Salaries & Other<br />Forms Of Compensation</div>
            <div class="flex-1">
                <ElementsInput v-model="employeePreviousSalaryData.nonTaxableSalariesAndOtherFormsOfCompensation" placeholder="Enter the To Date" class="flex-1" :full="true" :isNumber="true" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">TAXABLE Basic Salary</div>
            <div class="flex-1">
                <ElementsInput v-model="employeePreviousSalaryData.taxableBasicSalary" placeholder="Enter the To Date" class="flex-1" :full="true" :isNumber="true" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">13th Month & Other Benefits</div>
            <div class="flex-1">
                <ElementsInput v-model="employeePreviousSalaryData.taxable13thMonthAndOtherBenefits" placeholder="Enter the To Date" class="flex-1" :full="true" :isNumber="true" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Salaries & Other Compensation</div>
            <div class="flex-1">
                <ElementsInput v-model="employeePreviousSalaryData.taxableSalariesOtherCompensation" placeholder="Enter the To Date" class="flex-1" :full="true" :isNumber="true" />
            </div>
        </div>

        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Tax Withheld<br />Previous Employer</div>
            <div class="flex-1">
                <ElementsInput v-model="employeePreviousSalaryData.taxWithheldPresentEmployer" placeholder="Enter the To Date" class="flex-1" :full="true" :isNumber="true" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        employeePreviousSalaryOldData: Object,
        isOpen: Boolean,
    },
    data() {
        return {
            yearOptions: [],
            employeePreviousSalaryData: {
                employeeSalaryInfoPreviousSeq: null,
                companySeq: null,
                employeeSeq: null,
                employerName: '',
                tinNo: '',
                address: '',
                zipCode: '',
                taxableYear: '',
                taxableFrom: '',
                taxableTo: '',
                nonTaxableBasicSalary: 0,
                nonTaxable13thMonthAndOtherBenefits: 0,
                nonTaxableSssGsisPagibigAndUnionDues: 0,
                nonTaxableSalariesAndOtherFormsOfCompensation: 0,
                nonTaxableDeminimisBenefits: 0,
                taxableBasicSalary: 0,
                taxable13thMonthAndOtherBenefits: 0,
                taxableSalariesOtherCompensation: 0,
                taxWithheldPresentEmployer: 0,
            },

            formattedTin: null,
        }; //return
    }, //data
    mounted() {
        const self = this;
        self.yearOptions = self.getYearOptions();
    },
    watch: {
        isOpen: {
            handler(newValue) {
                if (!newValue) {
                    return;
                }

                const self = this;
                self.employeePreviousSalaryData = { ...self.employeePreviousSalaryOldData };
                self.changeTinNumberFormat(self.employeePreviousSalaryData.tinNo);
            },
        },
    },
    methods: {
        getYearOptions() {
            const self = this;
            const currentYear = new Date().getFullYear();
            const previousYear = currentYear - 1;

            return [currentYear, previousYear].map((y) => ({ text: y.toString(), value: y.toString() }));
        },
        getSalaryPreviousData() {
            const self = this;
            return self.employeePreviousSalaryData;
        },
        changeTinNumberFormat(input) {
            const self = this;

            const inputValue = input?.replace(/\D/g, ''); // Remove non-digit characters
            let formattedValue = '';

            if (inputValue) {
                //
                formattedValue = inputValue.match(new RegExp('.{1,3}', 'g')).join('-'); // xxx-xxx-xxx
                const regex = /^(\d{3})(\d{3})(\d{3})(\d{1,})$/;
                const match = regex.exec(inputValue);

                if (match) {
                    formattedValue = `${match[1]}-${match[2]}-${match[3]}-${match[4]}`; // xxx-xxx-xxxxx
                    self.formattedTin = formattedValue;
                }

                self.formattedTin = formattedValue;
            }

            self.employeePreviousSalaryData.tinNo = inputValue.replace(/\D/g, '');
        },
    },
};
</script>
