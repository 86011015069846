<script>
import ElementsInput from "@/components/elements/ElementsInput.vue";
import ElementsModal2Button from "@/components/elements/ElementsModal2Button.vue"
import { graduationCategory } from "@/assets/data/optionData.js";
import { educationTypes } from "@/assets/data/optionData.js";
import AppPopup from "@/components/AppPopup.vue";
import moment from "moment";

export default {
    components: {
        ElementsInput,
        AppPopup,
        ElementsModal2Button
    },
    props: {
        selectedEmployeeSeq : String,
    },

    data() {
        return {
            // 디비에서 가지고 와서 저장
            educations: [],
            // add
            popupItems: {
                employeeSeq: this.selectedEmployeeSeq,
                schoolName: null,
                degreeType: null,
                major: null,
                admissionDate: null,
                graduationDate: null,
                graduationCategory: null,
            },
        // edit
            popupEditItems: {
                employeeSeq: this.selectedEmployeeSeq,
                schoolName: null,
                degreeType: null,
                major: null,
                admissionDate: null,
                graduationDate: null,
                graduationCategory: null,
            },
            // modal
            showCancelCheckModal: false,
            showEditPopup: false,
            showPopup: false,

            educationSeq: null,
            requestItem: [],
            beforeEditData: {},
            saveData: {},
            graduationCategoryOptions: graduationCategory,
            educationTypes: educationTypes,
            showEditBtn:false,

        }; //return
    }, //data
    mounted() {
        this.getListEducation(this.selectedEmployeeSeq);
    },
    methods: {
        moment : moment,
        parseInt : parseInt,
        deleteEducationModalOpen() {
            const self = this;
            self.showEditPopup = false;
            self.showCancelCheckModal = true;
        },
        //데이터 삭제
        deleteEducation() {
            const self = this;
            const params = new URLSearchParams(document.location.search);
            params.append("educationSeq", self.educationSeq);
            // TODO:delete axios
            const url = self.$api("uri", "delete-employee-education").replace('{educationSeq}', self.educationSeq);
            self.$axios.delete(url)
                .then( res => {
                    self.showCancelCheckModal = false
                    self.getListEducation(self.selectedEmployeeSeq);
                })
                .catch(console.log);
        },
        getListEducation( employeeSeq ) {
            const self = this;
            const url = self.$api("uri", "get-employee-education")
                            .replace("{employeeSeq}", employeeSeq);

            self.$axios.get(url)
            .then((res) => {
                self.educations = res.data.data.list;
                self.showEditBtn=true;
            }).catch(err=>self.showEditBtn=false);
        },
        saveEducationAdd() {
            const self = this;

            if( ! self.validationEducation( self.popupItems ) ){
                return;
            }
            const employeeSeq = self.selectedEmployeeSeq;
            const requestItem = { ...self.popupItems, employeeSeq}

            const url = self.$api("uri", "post-employee-education");
            self.$axios
                .post(url, requestItem)
                .then((res) => {
                    self.showPopup = false;
                    self.getListEducation(employeeSeq);
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        addButtonClick() {
            const self = this;
            self.showPopup = true;
            self.popupItems = {
                employeeSeq: this.selectedEmployeeSeq,
                schoolName: null,
                degreeType: null,
                major: null,
                admissionDate: null,
                graduationDate: null,
                graduationCategory: null,
            }
        },
        editButtonClick(arg) {
            const self = this;
            self.showEditPopup = true;
            self.nowEdit = true;

            self.popupEditItems = { ...arg };
            self.educationSeq = self.popupEditItems.educationSeq;
        },
        editSaveButtonClick() {
            const self = this;

            if( ! self.validationEducation( self.popupEditItems ) ) {
                return;
            }

            const employeeSeq = self.selectedEmployeeSeq;
            const requestItem = { ...self.popupEditItems, employeeSeq}

            const url = self.$api("uri", "put-employee-education");
            self.$axios
                .put(`${url}/${encodeURIComponent(self.educationSeq)}`, requestItem)
                .then((res) => {
                    self.showEditPopup = false;
                    self.getListEducation(employeeSeq);
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        validationEducation( itemToValidate ){
            let {
                schoolName,
                admissionDate,
                graduationDate,
            } = itemToValidate;

            if(schoolName === null || schoolName === ""){
                alert("School Name can’t be blank");
                return false ;
            }

            if( parseInt(graduationDate)< parseInt(admissionDate) ){
                alert('Please enter the correct date')
                return false;
            }

            return true;
        }
    },
};
</script>
<template>
    <div>
        <Teleport to="body">
            <AppPopup v-model="showPopup" name="Add Education">
                <div class="pr-7 pb-8 pl-7">
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Type</div>
                            <ElementsSelectRef
                                v-model="popupItems.degreeType"
                                placeholder="Enter Type"
                                class="flex-1"
                                :options="educationTypes"
                                :full="true"
                            />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">
                            School Name
                        </div>
                        <ElementsInput
                            v-model="popupItems.schoolName"
                            placeholder="Enter School name"
                            class="flex-1"
                            :full="true"
                            style="width: 400px"
                        />
                    </div>
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">Major</div>
                            <ElementsInput
                                v-model="popupItems.major"
                                placeholder="Enter Role"
                                class="flex-1"
                                :full="true"
                            />
                        </div>
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">
                                Admission Date
                            </div>
                            <ElementsDatePicker
                                v-model="popupItems.admissionDate"
                                placeholder="Select Date"
                                class="flex-1 "
                                style="width: 420px"
                            />
                        </div>
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">
                                Graduation Date (including planned)
                            </div>
                            <ElementsDatePicker
                                v-model="popupItems.graduationDate"
                                placeholder="Select Date"
                                class="flex-1 mt-1 shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:blu-text"
                                style="width: 420px"
                            />
                        </div>
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">
                                Graduation Category
                            </div>
                            <ElementsSelectRef
                                v-model="popupItems.graduationCategory"
                                placeholder="Enter Graduation Category"
                                class="flex-1"
                                :options="graduationCategoryOptions"
                                :full="true"
                                :useDefaultMsg="true"
                            />
                        </div>
                </div>
                <!-- add button -->
                <div class="flex w-full bg-white">
                    <div class="w-full p-4 border-t bg-white-200">
                        <div class="text-right justify-end flex">
                            <ElementsButton
                                text="Cancel"
                                :width28="true"
                                :height12="true"
                                :background-white="true"
                                @click="showPopup=false"
                            />
                            <ElementsButton
                                class="ml-2"
                                text="Save"
                                :width28="true"
                                :height12="true"
                                @click="saveEducationAdd"
                            />
                        </div>
                    </div>
                </div>
            </AppPopup>
        </Teleport>
<!-- edit education -->
        <Teleport to="body">
            <AppPopup v-model="showEditPopup" name="Edit Education">
                <div class="pr-7 pb-8 pl-7">
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Type</div>
                        <ElementsSelectRef
                            v-model="popupEditItems.degreeType"
                            placeholder="Enter Type"
                            class="flex-1"
                            :options="educationTypes"
                            :full="true"
                        />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">
                            School Name
                        </div>
                        <ElementsInput
                            v-model="popupEditItems.schoolName"
                            placeholder="Enter School name"
                            class="flex-1"
                            :full="true"
                            style="width: 400px"
                        />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Major</div>
                        <ElementsInput
                            v-model="popupEditItems.major"
                            placeholder="Enter Role"
                            class="flex-1"
                            :full="true"
                        />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">
                            Dmission Date
                        </div>
                        <ElementsDatePicker
                            v-model="popupEditItems.admissionDate"
                            placeholder="Select Date"
                            inputFormat="MM-dd-yyyy"
                            class="flex-1 mt-1 shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:blu-text"
                            style="width: 420px"
                        />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">
                            Graduation Date (Including Planned)
                        </div>
                        <ElementsDatePicker
                            v-model="popupEditItems.graduationDate"
                            placeholder="Select Date"
                            inputFormat="MM-dd-yyyy"
                            class="flex-1 mt-1 shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:blu-text"
                            style="width: 420px"
                        />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">
                            Graduation Category
                        </div>
                        <ElementsSelectRef
                            v-model="popupEditItems.graduationCategory"
                            placeholder="Enter Graduation Category"
                            class="flex-1"
                            :options="graduationCategoryOptions"
                            :full="true"
                            :useDefaultMsg="true"
                        />
                    </div>
                    <!-- edit button -->
                    <div class="px-4 mt-3 flex justify-end items-felx-end w-full bg-white">
                        <ElementsButton
                            text="Delete"
                            :width28="true"
                            :height12="true"
                            :background-red="true"
                            @click="deleteEducationModalOpen"
                        />
                        <ElementsButton
                            class="ml-2"
                            text="Save"
                            :width28="true"
                            :height12="true"
                            @click="editSaveButtonClick"
                        />
                    </div>
                </div>
            </AppPopup>
            <ElementsModal2Button
                v-model="showCancelCheckModal"
                :modalTitle="'Delete Check'"
                :buttonText="'Confirm'"
                :modalContent="'Are you sure to Delete?'"
                @afterClick="deleteEducation"
            />
        </Teleport>
<!-- list -->
        <div>
            <div class="flex justify-between items-center mt-6 h-10">
                <h4 class="text-lg font-bold">Education({{ educations.length }})</h4>
                <button
                    v-show="$appUtil.checkPermission('EDUCATION_INFO_EDIT') && showEditBtn"
                    @click="addButtonClick"
                    class="border rounded border-blue-500 w-24 h-10 bg-white text-sm font-semibold text-blue-500 hover:bg-blue-100 transition-all duration-500 text-center"
                >
                    Add
                </button>
            </div>
            <div v-for="(education, index) in educations" v-bind:key="index">
                <div class="h-14 text-sm font-bold flex justify-between items-center">
                    <h5>  {{ education.schoolName }} </h5>
                    <button class="px-2 text-indigo-600" @click="editButtonClick(education)" v-show="$appUtil.checkPermission('EDUCATION_INFO_EDIT') && showEditBtn" >
                        Edit
                    </button>
                </div>
                <div class="border rounded-lg border-color-gray-200">
                    <div
                        class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4"
                    >
                        <h5 class="font-bold w-3/5">Type</h5>
                        <p v-if="!education.degreeType" class="font-medium text-gray-400 w-2/5">
                            -
                        </p>
                        <p v-else class="font-medium text-gray-400 w-2/5">
                            {{ education.degreeType }}
                        </p>
                    </div>
                    <div
                        class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4"
                    >
                        <h5 class="font-bold w-3/5">Major</h5>
                        <p v-if="!education.major" class="font-medium text-gray-400 w-2/5">
                            -
                        </p>
                        <p v-else class="font-medium text-gray-400 w-2/5">
                            {{ education.major }}
                        </p>
                    </div>
                    <div
                        class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4"
                    >
                        <h5 class="font-bold w-3/5">Dmission Date</h5>
                        <p v-if="!education.admissionDate || education.admissionDate === null" class="font-medium text-gray-400 w-2/5">
                            -
                        </p>
                        <p v-else class="font-medium text-gray-400 w-2/5">
                            {{ moment(parseInt(education.admissionDate), "YYYYMMDD").format("MM/DD/YYYY") }}
                        </p>
                    </div>
                    <div
                        class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4"
                    >
                        <h5 class="font-bold w-3/5">Graduation Date</h5>
                        <p v-if="!education.graduationDate || education.graduationDate === null" class="font-medium text-gray-400 w-2/5">
                            -
                        </p>
                        <p v-else class="font-medium text-gray-400 w-2/5">
                            {{ moment(parseInt(education.graduationDate), "YYYYMMDD").format("MM/DD/YYYY") }}
                        </p>
                    </div>
                    <div
                        class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4"
                    >
                        <h5 class="font-bold w-3/5">Graduation Category</h5>
                        <p v-if="!education.graduationCategory" class="font-medium text-gray-400 w-2/5">
                        -
                        </p>
                        <p v-else class="font-medium text-gray-400 w-2/5">
                            {{ education.graduationCategory }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
body {
    margin: 0;
}
div {
    box-sizing: border-box;
}
.black-bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    padding: 20px;
}
.white-bg {
    width: 100%;
    background: white;
    border-radius: 8px;
    padding: 20px;
}
</style>
