<template>
    <div class="contents_title flex justify-between mb-7">
        <p class="text-[22px] text-[#2E2C34] font-bold">Previous Employer(s)</p>
    </div>
    <div class="contents_detail">
        <div class="w-full flex justify-between mb-3">
            <p class="text-[#504F54] text-sm font-bold"></p>
            <a href="javascript:void(0)" @click="editButton">
                <p class="text-[#4640DE] text-sm font-bold">Edit</p>
            </a>
        </div>
        <div class="border rounded-lg border-color-gray-200">
            <div class="border-b border-color-gray-200 min-h-[60px] flex items-center text-sm px-4">
                <h4 class="text-lg font-bold">PREVIOUS EMPLOYER(S)</h4>
            </div>

            <div class="border-b border-color-gray-200 min-h-[60px] flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">Employer Name</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.employerName }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 min-h-[60px] flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">Employer TIN</h5>
                <p class="font-medium w-2/5">
                    {{ tinNumberFormat(employeePreviousSalaryData.tinNo) }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 min-h-[60px] flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">Address</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.address }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 min-h-[60px] flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">ZIP Code</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.zipCode }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 min-h-[60px] flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">Taxable Year</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.taxableYear }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">From Date</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.taxableFrom }}
                </p>
            </div>
            <div class="min-h-[60px] flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">To Date</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.taxableTo }}
                </p>
            </div>

            <div class="border-b border-color-gray-200 min-h-[60px] flex items-center text-sm px-4 mt-4">
                <h4 class="text-lg font-bold">NON-TAXABLE(From Previous Employer)</h4>
            </div>
            <div class="border-b border-color-gray-200 min-h-[60px] flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">Basic Salary<br />(P250,000 And Below)</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.nonTaxableBasicSalary }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 min-h-[60px] flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">13th Month & Other Benefits</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.nonTaxable13thMonthAndOtherBenefits }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">De Minimis Benefits</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.nonTaxableDeminimisBenefits }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">SSS, GSIS, PAG-IBIG<br />& Union Dues</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.nonTaxableSssGsisPagibigAndUnionDues }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">Salaries & Other<br />Forms Of Compensation</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.nonTaxableSalariesAndOtherFormsOfCompensation }}
                </p>
            </div>
            <div class="min-h-[60px] flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">Total Non-Taxable/Exempt<br />Compensation Income</h5>
                <p class="font-medium w-2/5">
                    {{
                        employeePreviousSalaryData.nonTaxableBasicSalary +
                        employeePreviousSalaryData.nonTaxable13thMonthAndOtherBenefits +
                        employeePreviousSalaryData.nonTaxableSssGsisPagibigAndUnionDues +
                        employeePreviousSalaryData.nonTaxableSalariesAndOtherFormsOfCompensation +
                        employeePreviousSalaryData.nonTaxableDeminimisBenefits
                    }}
                </p>
            </div>

            <div class="border-b border-color-gray-200 min-h-[60px] flex items-center text-sm px-4 mt-4">
                <h4 class="text-lg font-bold">TAXABLE(From Previous Employer)</h4>
            </div>
            <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">Basic Salary</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.taxableBasicSalary }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">13th Month & Other Benefits</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.taxable13thMonthAndOtherBenefits }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">Salaries & Other Compensation</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.taxableSalariesOtherCompensation }}
                </p>
            </div>
            <div class="min-h-[60px] flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">Total Taxable Compensation Income</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.taxableBasicSalary + employeePreviousSalaryData.taxable13thMonthAndOtherBenefits + employeePreviousSalaryData.taxableSalariesOtherCompensation }}
                </p>
            </div>

            <div class="border-b border-color-gray-200 min-h-[60px] flex items-center text-sm px-4 mt-4">
                <h4 class="text-lg font-bold">TAX WITHHELD(From Previous Employer)</h4>
            </div>
            <div class="min-h-[60px] flex items-center text-sm px-4">
                <h5 class="font-medium text-gray-400 w-3/5">Tax Withheld<br />Previous Employer</h5>
                <p class="font-medium w-2/5">
                    {{ employeePreviousSalaryData.taxWithheldPresentEmployer }}
                </p>
            </div>
        </div>
    </div>

    <Teleport to="body">
        <AppPopup v-model="editPopup" :name="'Edit Salary Previous'" button-text="Save" @button-event="insertOrEditPreviousSalary()" :width800="true">
            <AddOrEditSalaryPrevious :isOpen="editPopup" :employeePreviousSalaryOldData="employeePreviousSalaryData" ref="addOrEditSalaryPrevious" />
        </AppPopup>
    </Teleport>
</template>

<script>
import AddOrEditSalaryPrevious from '@/pages/employees/employees/popups/PopupAddOrEditSalaryPrevious.vue';
import { ValidateUtil } from '@/plugins/app-util';

export default {
    components: {
        AddOrEditSalaryPrevious,
    },
    props: {
        employeeData: Object,
    },
    data() {
        return {
            editPopup: false,
            employeePreviousSalaryData: {
                employeeSalaryInfoPreviousSeq: null,
                companySeq: null,
                employeeSeq: null,
                employerName: '',
                tinNo: '',
                address: '',
                zipCode: '',
                taxableYear: '',
                taxableFrom: '',
                taxableTo: '',
                nonTaxableBasicSalary: 0,
                nonTaxable13thMonthAndOtherBenefits: 0,
                nonTaxableSssGsisPagibigAndUnionDues: 0,
                nonTaxableSalariesAndOtherFormsOfCompensation: 0,
                nonTaxableDeminimisBenefits: 0,
                taxableBasicSalary: 0,
                taxable13thMonthAndOtherBenefits: 0,
                taxableSalariesOtherCompensation: 0,
                taxWithheldPresentEmployer: 0,
            },
        }; //return
    }, //data
    mounted() {
        const self = this;

        if (self.employeeData != null && self.employeeData.employeeSeq != null) {
            const url = self.$api('uri', 'get-salary-previous');

            let jsonParam = JSON.stringify(self.employeeData.employeeSeq);
            self.$axios
                .get(url, { params: { jsonParam } })
                .then((res) => {
                    if (res.data.data.employeePreviousSalaryData == null) {
                        self.employeePreviousSalaryData.companySeq = self.employeeData.companySeq;
                        self.employeePreviousSalaryData.employeeSeq = self.employeeData.employeeSeq;
                    } else {
                        self.employeePreviousSalaryData = res.data.data.employeePreviousSalaryData;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    },
    methods: {
        editButton() {
            const self = this;
            self.editPopup = true;
        },
        insertOrEditPreviousSalary() {
            const self = this;

            const salaryPreviousData = self.$refs.addOrEditSalaryPrevious.getSalaryPreviousData();
            if (self.invalidEmployeeSalaryPreviousForm(salaryPreviousData)) {
                return;
            }

            const requestItem = { ...salaryPreviousData };
            const url = self.$api('uri', 'post-salary-previous');
            self.$axios
                .post(url, requestItem)
                .then((res) => {
                    self.editPopup = false;
                    self.employeePreviousSalaryData = salaryPreviousData;
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        invalidEmployeeSalaryPreviousForm(formData) {
            const self = this;

            const { employerName, tinNo, taxableYear, taxableFrom, taxableTo } = formData;
            if ([employerName, tinNo, taxableYear, taxableFrom, taxableTo].some((i) => !i)) {
                let msg = 'Please provide the required information. These field cannot be left empty.\n';
                msg += 'Employee Name, Tin Number, Taxable Year, Taxable From Date , Taxable To Date ';
                alert(msg);
                return true;
            }

            for (let key in formData) {
                if (key == 'employeeSalaryInfoPreviousSeq' || key == 'employerName' || key == 'address') {
                    continue;
                } else if (key == 'companySeq') {
                    if (ValidateUtil.invalidString(formData.companySeq)) {
                        alert('Company information is unknown. Please reload.');
                        return true;
                    }
                } else if (key == 'employeeSeq') {
                    if (ValidateUtil.invalidString(formData.employeeSeq)) {
                        alert('Employee information is unknown. Please reload.');
                        return true;
                    }
                } else if (key == 'tinNo') {
                    if (formData.tinNo.length < 12 || formData.tinNo.length > 14) {
                        alert('Please enter a valid Tax Identification Number (TIN) in the format of 12 to 14 digits');
                        return true;
                    }
                } else if (key == 'zipCode') {
                    if (formData.zipCode.length != 4) {
                        alert('ZIP Code digits is must be 4');
                        return true;
                    }
                } else if (key == 'taxableYear') {
                    if (formData.taxableYear.length != 4) {
                        alert(`Taxable Year format is [YYYY] ${formData.taxableYear.length}`);
                        return true;
                    }
                } else if (key == 'taxableFrom') {
                    if (formData.taxableFrom.length != 4) {
                        alert('Taxable From format is [MMDD]');
                        return true;
                    }
                } else if (key == 'taxableTo') {
                    if (formData.taxableTo.length != 4) {
                        alert('Taxable to format is [MMDD]');
                        return true;
                    }
                } else {
                    // 나머지는 값 타입 임.
                    formData[key] = String(formData[key]).replaceAll(',', '');
                    formData[key] = Number(Number.parseFloat(formData[key]).toFixed(2));
                    if (self.invalidDecimal(formData[key])) {
                        return true;
                    }
                }
            }

            return false;
        },
        invalidDecimal(value) {
            // 현재 서버는 Decimal 18, 2 이지만
            // javascript Integer Precision 이 15 digits 이기 때문에 13, 2로 제한 합니다.
            let epsilon = 0.000001;
            let min = -0.01;
            let max = 10000000000000;
            if (Number.isNaN(value)) {
                alert('Only numbers must be entered for data other than your previous employer information.');
                return true;
            }

            if (ValidateUtil.invalidDecimal(min, max, epsilon, value)) {
                alert('Amount must be 0 or more and less than 10,000,000,000,000.(0.01 ~ 10,000,000,000,000');
                console.log(value);
                return true;
            }

            return false;
        },
        tinNumberFormat(input) {
            const self = this;

            const inputValue = input?.replace(/\D/g, ''); // Remove non-digit characters
            let formattedValue = '';

            if (inputValue) {
                //
                formattedValue = inputValue.match(new RegExp('.{1,3}', 'g')).join('-'); // xxx-xxx-xxx
                const regex = /^(\d{3})(\d{3})(\d{3})(\d{1,})$/;
                const match = regex.exec(inputValue);

                if (match) {
                    formattedValue = `${match[1]}-${match[2]}-${match[3]}-${match[4]}`; // xxx-xxx-xxxxx
                    self.formattedTin = formattedValue;
                }
            }

            return formattedValue;
        },
    },
};
</script>
