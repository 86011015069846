<script setup>
import { ref, reactive } from 'vue';
import { onMounted, defineEmits, defineProps, defineExpose, watch } from 'vue';

const props = defineProps({
    modelValue: String,
    name: String,
    options: Array,
    error: String,
    full: Boolean,
    width72: Boolean,
    width60: Boolean,
    width40: Boolean,
    width36: Boolean,
    width25rem: Boolean,
    height12: Boolean,
    placeholder: String,
    readonly: { type: Boolean, default: false },
    useDefaultMsg: { type: Boolean, default: false },
});

const emit = defineEmits(['update:modelValue', 'change']);
const selectText = ref('');
const showOptions = ref(false);
const optionList = ref([...props.options]);

onMounted(() => {
    if (props.useDefaultMsg) {
        optionList.value.unshift({ text: props.placeholder, value: null });
    }

    const option = optionList.value.find((option) => option.value === props['modelValue']);
    if (option) {
        selectText.value = option.text;
    }
});

function clickSelect() {
    if (!props['readonly']) {
        showOptions.value = !showOptions.value;
    }
}

function clickOption(option) {
    emit('update:modelValue', option.value);
    selectText.value = option.text;
    clickSelect();
    emit('change');
}

function updateSelectText() {
    const option = optionList.value.find((option) => option.value === props.modelValue);
    if (option) {
        selectText.value = option.text;
    } else {
        selectText.value = '';
    }
}

// watch를 사용하여 modelValue가 변경될 때 updateSelectText를 호출
watch(
    () => props.modelValue,
    () => {
        updateSelectText();
    }
);

// watch를 사용하여 options가 변경될 때 updateSelectText를 호출
watch(
    () => props.options,
    (newOptions) => {
        optionList.value = [...newOptions];
        if (props.useDefaultMsg) {
            optionList.value.unshift({ text: props.placeholder, value: null });
        }
        updateSelectText();
    },
    { deep: true }
);

defineExpose({});
</script>

<template>
    <div>
        <h1 class="text-sm font-semibold text-slate-800">{{ name }}</h1>
        <div
            class="relative border rounded-lg py-1 px-3 max-w-6xl h-12 bg-white transition-all duration-500 shadow shadow-gray-100"
            :class="{
                'border-red-500': error,
                'w-96': !full && !width72 && !width60 && !width40 && !width36 && !width25rem,
                'w-full': full,
                'w-72': width72,
                'w-60': width60,
                'w-40': width40,
                'w-36': width36,
                'w-[25rem]': width25rem,
                'mt-3': name,
                'bg-gray-200': readonly,
                'h-12': height12,
            }">
            <a class="w-full h-full text-sm font-semi-bold cursor-pointer flex justify-between items-center" href="javascript:void(0)" @click="clickSelect">
                <div class="truncate" :class="[!selectText && placeholder ? 'text-gray-400' : '', width40 || width36 ? 'w-[80%]' : 'w-[90%]']">
                    {{ selectText ? selectText : placeholder }}
                </div>
                <div :class="[width40 || width36 ? 'w-[20%]' : 'w-[10%]']">
                    <img src="@/assets/img/elements-select-1.svg" alt="elements-select-1" v-show="!readonly" />
                </div>
            </a>
            <div
                v-show="showOptions"
                class="absolute top-11 left-0 border rounded-sm max-w-6xl max-h-36 bg-white transition-all duration-500 shadow shadow-gray-100 overflow-auto z-10"
                :class="{
                    'border-red-500': error,
                    'w-96': !full && !width72 && !width60 && !width40 && !width36 && !width25rem,
                    'w-full': full,
                    'w-72': width72,
                    'w-60': width60,
                    'w-40': width40,
                    'w-36': width36,
                    'w-[25rem]': width25rem,
                }">
                <a
                    v-for="option in optionList"
                    :key="option.value"
                    class="block px-3 py-0.5 w-full bg-white transition-all duration-500 cursor-pointer text-sm hover:bg-blue-500 hover:text-white"
                    :class="{
                        'bg-gray-300 hover:bg-gray-300 hover:text-black': option.disabled,
                        'bg-gray-300': readonly,
                    }"
                    @click="option.disabled === true ? doNothing : clickOption(option)"
                    >{{ option.text }}</a
                >
            </div>
        </div>
        <p class="mt-3.5 text-xs font-normal text-red-500" v-show="error">*{{ error }}</p>
    </div>
</template>
