import moment from 'moment';

var RecaptchaUtil = {
    setRecaptchaJs: function () {
        var scriptEl = document.createElement('script');
        scriptEl.src = '//www.google.com/recaptcha/api.js';
        document.head.appendChild(scriptEl);
    },
    checkReCaptcha: function () {
        if (typeof grecaptcha === 'undefined') return;

        const reCaptchaKey = window.grecaptcha.getResponse();
        if (reCaptchaKey.length > 0) return reCaptchaKey;
        return;
    },
};

// -------------------------------------------------------------------------------------------------

var ValidateUtil = {
    errMsg: {
        email: {
            empty: '*You must enter an email.',
            form: '*Please enter a valid email address.',
        },
        pwd: {
            empty: '*Password can’t be blank.',
            length: '*Password must be min 8 characters and Max 20 characters.',
        },
        captcha: {
            empty: '*Please confirm the CAPTCHA.',
        },
        confirmPwd: {
            empty: '*Password confirmation can’t be blank.',
            match: '*Password confirmation doesn’t match password.',
        },
        name: {
            empty: '*Name can’t be blank.',
            length: '*Name must be min 2 characters and Max 15 characters.',
        },
        phoneNo: {
            empty: '*Mobile number can’t be blank.',
            length: '*Mobile number must be min 10 numbers and max 12 numbers.',
        },
        agree: {
            signUpCheck: '*Promo T&C and Data Privacy must be accepted.',
        },
    },
    checkIsEmpty(word) {
        return word == null || word.trim() == '';
    },
    // 입력을 안해도 되는 데이터에서 사용
    checkIsEmptyChangeSpace(word) {
        if (word == null || word.trim() == '') {
            word = null;
        }
        return word;
    },
    // 입력을 안해도 되는 데이터(날짜)에서 사용
    checkIsEmptyDeleteDate(word) {
        if (word == null || word.trim() == '') {
            word = null;
        }
        return word;
    },
    // 입력을 안해도 되는 데이터(날짜)에서 사용 null 을 반환
    checkIsEmptyChangeNull(word) {
        if (word == null || word.trim() == '') {
            word = null;
        }
        return word;
    },
    checkIsNumber(word) {
        if (/[^\d]/.test(word)) {
            return false;
        }
        return true;
    },
    checkIsEmail(word) {
        return /.+@.+\..+/.test(word);
    },
    checkPassword(word) {
        // 현재는 자릿수만 체크
        if (word == null) return false;
        return word.length >= 8 && word.length <= 20;
    },
    checkName(word) {
        if (word == null) return false;
        return word.trim().length >= 2 && word.trim().length <= 100;
    },
    checkNameForSMS(word) {
        if (word == null) return false;
        return word.trim().length >= 2 && word.trim().length <= 7;
    },
    checkPhone(word) {
        if (word == null) return false;
        return word.trim().length >= 10 && word.trim().length <= 12;
    },
    checkZipcode(word) {
        if (this.checkIsEmpty(word)) return;
        return word.trim().length <= 7;
    },
    checkTinNumber(tin) {
        if (!tin) return false;
        if (/[^\d]/.test(tin)) return false;

        return tin.trim().length == 12;
    },
    matchPassword(pwd, cPwd) {
        return pwd == cPwd;
    },
    invalidPhoneNumber(phoneNum) {
        if (phoneNum == null || typeof phoneNum != 'string') {
            return true;
        }

        if (phoneNum.startsWith('63')) {
            phoneNum = phoneNum.substring(2);
        }

        if (!phoneNum || phoneNum.length < 7 || phoneNum.length > 20) {
            return true;
        }
        return false;
    },
    removeCountryCodePhoneNumber(word) {
        let phoneNum = '';
        if (!word) {
            return null;
        }

        if (word.startsWith('63')) {
            phoneNum = word.substring(2);
        } else {
            phoneNum = word;
        }

        if (phoneNum.length < 7 || phoneNum.length > 20) {
            return null;
        }

        return phoneNum;
    },
    convertPhoneGlobalToLocal(word) {
        // 추후 번호를 다르게 표시해야 할 경우를 대비해서 이 부분에 연관된 페이지를 확인하기 위해 해당 함수 삭제하지 않고 남겨 둠.
        let phoneNum = '';
        if (!word) {
            return null;
        }

        if (word.startsWith('63')) {
            phoneNum = word.substring(2);
        } else {
            phoneNum = word;
        }

        if (phoneNum.length < 7 || phoneNum.length > 20) {
            return null;
        }

        return phoneNum;
    },
    over15Charaters(word) {
        if (!word) return word;

        if (word.length < 16) return word;
        return word.substring(0, 16) + '...';
    },
    over15CharFromFullName(word) {
        if (!word) return word;

        const [first, middle, last] = word.split(' ');
        if (!middle && !last) return ValidateUtil.over15Charaters(first);
        if (!last) return ValidateUtil.over15Charaters([first, middle].join(', '));
        return ValidateUtil.over15Charaters([first, last].join(', '));
    },
    over15CharFromFirstOnly(word) {
        if (!word) return word;
        const [first, middle, last] = word.split(' ');
        return ValidateUtil.over15Charaters(first);
    },
    employeeNameSave(first, middle, last) {
        [first, middle, last] = [first, middle, last].map(this.trimData);

        if (!first || !last) return;
        if (!middle) return [first, last].join(' ');
        return [first, middle, last].join(' ');
    },
    trimData(word) {
        if (!word) return word;
        return word.trim();
    },
    numberWithCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    invalidString(str) {
        return typeof str === 'undefined' || str === null || str === '';
    },
    invalidDecimal(min, max, epsilon, checkValue) {
        let greaterThanMin = checkValue - min > epsilon && Math.abs(checkValue - min) > epsilon;
        let lessThanMax = checkValue - max < epsilon && Math.abs(checkValue - max) > epsilon;
        return !greaterThanMin || !lessThanMax;
    },
};

// ----------------------------------------------------------------------------------
const DefaultImage = {
    imgUrl: '/images/empty_image_rectangle.png',
    imgUrlForMain: '/images/empty_image_square.png',
    replaceByDefault(e) {
        e.target.src = DefaultImage.imgUrl;
    },
    replaceByDefaultForMain(e) {
        e.target.src = DefaultImage.imgUrlForMain;
    },
};
const CssClassUtil = {
    getProfileBackColor(count) {
        const colorArray = ['bg-orange-400', 'bg-green-400', 'bg-rose-400', 'bg-blue-500', 'bg-purple-400'];
        const number = count % colorArray.length;
        return colorArray[number];
    },
};
// -------------------------------------------------------------------------------------------------

const NumberFormatUtil = {
    fitDecimalFormatWithComma(value, fraction = 2) {
        if (value === null) {
            return '';
        }
        if (Number.isNaN(value)) {
            return String(value);
        }
        let regexp = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;
        let result = Number.parseFloat(value).toFixed(fraction).toString().replace(regexp, ',');
        return result;
    },
};

const DateFormatUtil = {
    fitDateFormat(value) {
        if (!value) {
            return;
        }
        return moment(value).format('MM/DD/yyyy');
    },
    monthDayFormat(value) {
        if (!value) {
            return;
        }
        return moment(value).format('MM/DD');
    },
    convertToDateTimeForServerFormat(date) {
        if (!date) {
            return;
        }
        return moment(date).hours(0).minutes(0).seconds(0).format('YYYY-MM-DD HH:mm:ss');
    },
};

export { RecaptchaUtil, ValidateUtil, DefaultImage, CssClassUtil, NumberFormatUtil, DateFormatUtil };
