import { SETTING_MODE, DISTRIBUTION_BASE, PAYROLL_FREQUENCY } from "../constants/payroll-template-enum";
import moment from 'moment';

const PayrollTemplateValidator = {
    invalidSettingMode(value) {
        if (!Object.values(SETTING_MODE).includes(value)) {
            alert("[SETTING_MODE] value is strange. Please select correct time unit option");
            console.log(Object.values(SETTING_MODE));
            console.log(value);
            return true;
        }
        return false;
    },
    invalidString(str) {
        return typeof str === "undefined" || str === null || str === "";
    },
    getOriginOrDefault(str, defaultString){
        if (this.invalidString(str)) {
            str = defaultString;
        }
        return str ;
    },
    invalidDecimal(min, max, epsilon, checkValue) {
        let greaterThanMin = (checkValue - min > epsilon) && (Math.abs(checkValue - min) > epsilon);
        let lessThanMax = (checkValue - max < epsilon) && (Math.abs(checkValue - max) > epsilon);
        return !greaterThanMin || !lessThanMax;
    },
    isReadMode(value) {
        return SETTING_MODE.READ === value;
    },
    isAddMode(value) {
        return SETTING_MODE.ADD === value;
    },
    isEditMode(value) {
        return SETTING_MODE.EDIT === value;
    },
    isDistributionBaseAmount(value) {
        return DISTRIBUTION_BASE.AMOUNT === value;
    },
    isDistributionBaseTier(value) {
        return DISTRIBUTION_BASE.TIER === value;
    },
    isDistributionBaseTeam(value) {
        return DISTRIBUTION_BASE.TEAM === value;
    },
    isMonthly(value) {
        return PAYROLL_FREQUENCY.MONTHLY === value;
    },
    isSemiMonthly(value) {
        return PAYROLL_FREQUENCY.SEMI_MONTHLY === value;
    },
    isWeekly(value) {
        return PAYROLL_FREQUENCY.WEEKLY === value;
    }
};

const PayrollTemplateStringMaker = {
    fitDecimalFormatWithComma(value, fraction = 2) {
        if (value == null || Number.isNaN(value)) {
            return value;
        }
        var regexp = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;
        return Number.parseFloat(value).toFixed(fraction).toString().replace(regexp, ',')
    },
    fitRegDateFormat(value) {
        return moment(value).format("MM/DD/yyyy");
    }
}

const PayrollDateTimeConverter = {
    convertToDateTimeForViewFormat(date, format = "MM/DD/yyyy[\r\n]hh:mm:ss"){
        if(!date) {
            return;
        }
        return moment(date).format(format);
    },
    convertToDateFormat(date, format= "MM/DD/yyyy") {
        if(!date) {
            return;
        }
        return moment(date).format(format);
    },
    convertToDateTimeForServerFormat(date) {
        if (!date) {
            return;
        }
        return moment(date).hours(0).minutes(0).seconds(0).format('YYYY-MM-DD HH:mm:ss');
    }
};

export {
    PayrollTemplateValidator,
    PayrollTemplateStringMaker,
    PayrollDateTimeConverter
};