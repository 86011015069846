const MONEY_UNIT = Object.freeze({
    CASH : "CASH",
    FLEX_BEN : "FLEX_BEN"
});

const DEDUCTION_TYPE = Object.freeze({
    RECURRING : "RECURRING",
    ADJUSTMENT : "ADJUSTMENT"
});

const PAYROLL_TYPE = Object.freeze({
    USER_CUSTOM : "USER_CUSTOM",
    THIRTEEN_MONTH_PAY : "THIRTEEN_MONTH_PAY",
    FINAL_PAY : "FINAL_PAY"
});

export {
    MONEY_UNIT,
    DEDUCTION_TYPE,
    PAYROLL_TYPE
};