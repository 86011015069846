<script setup>
import { defineProps, defineEmits } from 'vue';

defineProps({
    modelValue: Boolean,
    buttonText: String,
    disabled: Boolean,
    hideTitle: Boolean,
    title: String,
    noneOverflowAuto: { type: Boolean, default: false },
    width800: { type: Boolean, default: false },
    maxHeight: { type: String, default: '70vh' },
    minWidth: { type: String, default: '600px' },
    height: { type: String, default: '20rem' },
    width: { type: String, default: '40.25rem' },
});

const emit = defineEmits(['update:modelValue', 'buttonEvent', 'afterClose']);

function hidePopup() {
    emit('update:modelValue', false);
    emit('afterClose');
}

function buttonEvent() {
    emit('buttonEvent');
}
</script>

<template>
    <div v-show="modelValue" class="fixed top-0 w-full h-full bg-black/50 z-[80] flex justify-center items-center">
        <div class="border rounded-lg shadow shadow-gray-100 bg-white" :style="[width800 ? 'width: 100%;  max-width: 800px;' : '']">
            <div class="" :class="[noneOverflowAuto ? '' : 'overflow-auto', 'max-h-[' + maxHeight + ']', 'min-w-[' + minWidth + ']', 'h-[' + height + ']', 'w-[' + width + ']']">
                <template v-if="hideTitle">
                    <slot />
                </template>
                <template v-else>
                    <div class="h-[4.125rem] px-7 py-4 flex justify-center items-center text-center text-center">
                        <h2 class="text-[#2E2C34] text-[22px] font-bold">{{ title }}</h2>
                    </div>
                    <div class="w-full h-px bg-gray-200"></div>
                    <div class="min-h-[9.5rem] flex flex-col justify-center items-center space-y-4 p-5">
                        <div class="p-4 space-y-6 text-center text-[18px] text-[#504F54]">
                            <slot />
                        </div>
                    </div>
                    <div class="w-full h-px bg-gray-200"></div>
                    <div class="h-[6.25rem] flex justify-center items-center gap-4">
                        <button
                            class="h-[3.125rem] w-[10rem] border-[1px] border-[#D6DDEB] rounded-lg text-sm font-semibold text-[#4261EE] text-center bg-white hover:bg-[#D6DDEB]"
                            type="button"
                            @click="hidePopup">
                            Cancel
                        </button>
                        <button
                            class="h-[3.125rem] w-[10rem] border-[1px] border-[#D6DDEB] rounded-lg text-sm font-semibold text-white text-center bg-[#4261EE] hover:bg-[#D6DDEB]"
                            type="button"
                            @click="buttonEvent">
                            {{ buttonText || 'Confirm' }}
                        </button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
