<script setup>
import { defineProps, defineEmits } from 'vue';
import { InformationCircleIcon } from '@heroicons/vue/outline';
import ElementsTooltipSingleton from '@/components/elements/ElementsTooltipSingleton';
import ElementsButton from '@/components/elements/ElementsButton.vue';

defineProps({
    modelValue: Boolean,
    name: String,
    tooltip: String,
    buttonText: String,
    height12: Boolean,
    backgroundWhite: Boolean,
    backgroundRed: Boolean,
    disabled: Boolean,
    centerTitle: Boolean,
    noneOverflowAuto: { type: Boolean, default: false },
    width800: { type: Boolean, default: false },
});

const emit = defineEmits(['update:modelValue', 'buttonEvent', 'afterClose']);

function hidePopup() {
    emit('update:modelValue', false);
    emit('afterClose');
}

function buttonEvent() {
    emit('buttonEvent');
}

let __tooltipComponent__hidden__ = null;

function loadTooltip(ele, msgFn) {
    __tooltipComponent__hidden__.loadBasic(ele, msgFn);
}

function loadTooltipElementReady(ele) {
    if (ele == null) return; // auto-reload, so element can be null sometime.
    __tooltipComponent__hidden__ = ele;
}
</script>

<template>
    <div v-show="modelValue" class="fixed top-0 w-full h-full bg-black/50 z-[80] flex justify-center items-center">
        <div class="border rounded-lg shadow shadow-gray-100 bg-white" :style="[width800 ? 'width: 100%;  max-width: 800px;' : '']">
            <div class="px-7 py-6 flex justify-between items-center">
                <div v-if="centerTitle"></div>
                <h2 class="text-xl font-bold">
                    {{ name }}
                    <template v-if="tooltip">
                        <ElementsTooltipSingleton
                            :ref="
                                (el) => {
                                    loadTooltipElementReady(el);
                                }
                            " />
                        <InformationCircleIcon
                            class="h-5 w-5 ml-1 text-blue-600"
                            aria-hidden="true"
                            style="display: inline"
                            :ref="
                                (el) => {
                                    loadTooltip(el, () => {
                                        return tooltip;
                                    });
                                }
                            " />
                    </template>
                </h2>
                <a href="javascript:void(0)" class="ml-20" @click="hidePopup">
                    <img src="@/assets/img/app-popup-1.svg" alt="app-popup-1" />
                </a>
            </div>
            <div class="w-full h-px bg-gray-200"></div>
            <div class="max-h-[70vh] min-w-[600px]" :class="[noneOverflowAuto ? '' : 'overflow-auto']">
                <slot />
            </div>
            <template v-if="buttonText">
                <div class="w-full h-px bg-gray-200"></div>
                <div class="px-4 py-6 flex justify-end">
                    <ElementsButton text="Cancel" :width28="true" :height12="true" :background-white="true" @click-event="hidePopup" />
                    <ElementsButton
                        class="ml-2"
                        :backgroundWhite="backgroundWhite"
                        :backgroundRed="backgroundRed"
                        :disabled="disabled"
                        :text="buttonText"
                        :width28="true"
                        :height12="true"
                        @click-event="buttonEvent" />
                </div>
            </template>
        </div>
    </div>
</template>
