<script>
import moment from 'moment';

export default {
    props: {
        modelValue: Boolean,
        name: String,
        buttonText: String,
        checkInviteName: String,
        checkButtonText: String,
        // showExcelErrorButton : Boolean
    },
    emits: ['update:modelValue', 'closeInviteBulkPopup', 'afterClose', 'successInvite'],
    data() {
        return {
            showExcelErrorButton: false,
            showNoFileErrorString: false,
            ableAddButton: true,

            showCheckInviteBulkPopup: false,
            checkInvateList: { firstName: [], lastName: [], phoneNumber: [], inviteEmail: [], joinDate: [] },
            errorMessageExcelPath: '',
            sendEmployeeData: [],

            getInviteList: [],
        };
    },
    mounted() {
        // const self = this;
    },
    methods: {
        resetData() {
            const self = this;
            self.$refs.bulkFile.value = null;
            self.ableAddButton = true;
            self.showExcelErrorButton = false;
            self.showNoFileErrorString = false;
        },
        clickAddButton() {
            const self = this;

            self.$emit('update:modelValue', false);
            self.showCheckInviteBulkPopup = true;
        },
        clickUploadButton(event) {
            const self = this;
            event.preventDefault();

            if (self.$refs.bulkFile.value == null || self.$refs.bulkFile.value === '') {
                self.showNoFileErrorString = true;
                return;
            }

            const formProps = new FormData(self.$refs['fileUploadForm']);
            const url = self.$api('uri', 'post-invite-bulk-xlsx');

            self.$axios
                .post(url, formProps, {
                    headers: {
                        'Content-Type': 'multipart/form-data;',
                    },
                })
                .then((res) => {
                    const invalidFile = res.data.data.invalidFile;
                    console.log(invalidFile);
                    if (invalidFile) {
                        self.errorMessageExcelPath = res.data.data.data;
                        self.ableAddButton = false;
                        self.showExcelErrorButton = true;
                        self.showNoFileErrorString = false;
                        return;
                    }

                    self.getInviteList = [];
                    self.checkInvateList = { firstName: [], lastName: [], phoneNumber: [], inviteEmail: [], joinDate: [] };
                    //upload 한 excel 에 문제가 없을 경우 확인 팝업에서 list 확인 가능
                    self.getInviteList = res.data.data.data;

                    self.ableAddButton = false;
                    self.showExcelErrorButton = false;
                    self.showNoFileErrorString = false;
                })
                .catch((err) => {
                    console.error('err : ', err);
                });
        },
        clickExcelErrorButton() {
            const self = this;

            const url = self.$api('uri', 'get-invite-bulk-error');
            const json_query = JSON.stringify({
                excelFilePath: self.errorMessageExcelPath,
            });

            location.href = url + `?${encodeURIComponent('json_query')}=${encodeURIComponent(json_query)}`;
        },
        clickInviteBulkButton() {
            const self = this;

            self.sendEmployeeData = [];

            self.getInviteList.forEach((employee) => {
                let employeeName = '';

                let employeeObj = {
                    employeeName: '',
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    joinDate: moment().format('YYYYMMDD'),
                    inviteEmail: '',
                    department: null,
                    head: null,
                    tier: null,
                    workSchedule: null,
                    office: null,
                    paymentType: null,
                    basicSalaryAmount: null,
                    taxCode: null,
                    tinNo: null,
                    sssNo: null,
                    pagIbigNo: null,
                    philHealthNo: null,
                    rdoCode: null,
                    bankName: null,
                    accountNumber: null,
                };

                employeeName += employee.firstName.trim() + ' ';
                employeeName += employee.lastName.trim();

                employeeObj.employeeName = employeeName;
                employeeObj.firstName = employee.firstName;
                employeeObj.lastName = employee.lastName;
                employeeObj.phoneNumber = employee.phoneNumber;
                employeeObj.joinDate = employee.joinDate;
                employeeObj.inviteEmail = employee.inviteEmail;

                employeeObj.department = employee.department;
                employeeObj.head = employee.head;
                employeeObj.tier = employee.tier;
                employeeObj.workSchedule = employee.workSchedule;
                employeeObj.office = employee.office;
                employeeObj.paymentType = employee.paymentType;
                employeeObj.basicSalaryAmount = employee.basicSalaryAmount;
                employeeObj.taxCode = employee.taxCode;
                employeeObj.tinNo = employee.tinNo;
                employeeObj.sssNo = employee.sssNo;
                employeeObj.pagIbigNo = employee.pagIbigNo;
                employeeObj.philHealthNo = employee.philHealthNo;
                employeeObj.rdoCode = employee.rdoCode;
                employeeObj.bankName = employee.bankName;
                employeeObj.accountNumber = employee.accountNumber;

                //string 만 trim
                for (let key in employeeObj) {
                    employeeObj[key] = typeof employeeObj[key] === 'string' ? employeeObj[key].trim() : employeeObj[key];
                }

                self.sendEmployeeData.push(employeeObj);
            });

            const url = self.$api('uri', 'post-invite-bulk');

            self.$axios
                .post(url, self.sendEmployeeData)
                .then((res) => {
                    self.checkHidePopup();
                    self.$emit('successInvite', 'It is successfully invited ');
                })
                .catch((err) => {
                    console.error('err ? : ', err);
                });
        },
        clickCancelButton() {
            const self = this;
            self.$emit('update:modelValue', false);
        },
        hidePopup() {
            const self = this;

            self.$emit('update:modelValue', false);
            self.$emit('afterClose');
        },
        checkHidePopup() {
            const self = this;
            self.showCheckInviteBulkPopup = false;
        },
        buttonEvent() {
            const self = this;

            self.$emit('buttonEvent');
        },
        convertDateTimeToDate(dateTime) {
            return moment(dateTime).format('MM/DD/YYYY');
        },
    },
};
</script>

<template>
    <div v-show="modelValue" class="z-[80] fixed top-0 w-full h-full bg-black/50 flex justify-center items-center">
        <div class="border rounded-lg shadow shadow-gray-100 bg-white">
            <div class="px-7 py-6 flex justify-between items-center">
                <h2 class="text-xl font-bold">{{ name }}</h2>
                <a href="javascript:void(0)" class="ml-20" @click="hidePopup">
                    <img src="@/assets/img/app-popup-1.svg" alt="app-popup-1" />
                </a>
            </div>
            <div class="w-full h-px bg-gray-200"></div>
            <div class="max-h-[70vh] min-w-[600px] overflow-auto">
                <!-- slot -->
                <div class="px-7 py-6 w-[600px] max-h-96 overflow-auto">
                    <!-- template 를 다운받을때 .png 형식으로 받아야함. 다운로드 될때는 xlsx 형식으로 됨  -->
                    <a v-if="$appUtil.checkSubscription('FLEXBEN_ONLY_USAGE')" class="block" :href="require(`@/assets/file/flexbenOnlyBulkInviteTemplate.xlsx.png`)" download="bulkInviteTemplate.xlsx">
                        <ElementsButton text="Download the template" class="w-full" :widthFull="true" />
                    </a>
                    <a v-else class="block" :href="require(`@/assets/file/bulkInviteTemplate.xlsx.png`)" download="bulkInviteTemplate.xlsx">
                        <ElementsButton text="Download the template" class="w-full" :widthFull="true" />
                    </a>
                    <form @submit="clickUploadButton" ref="fileUploadForm">
                        <label class="mt-5 mb-3 block text-sm font-mediu text-zinc-500 font-bold">Please upload the template excel</label>
                        <div class="mt-1 flex rounded-md shadow-sm">
                            <div class="relative flex items-stretch flex-grow focus-within:z-10">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"></div>
                                <input type="file" name="multipartFile" class="w-full sm:text-sm rounded-l border py-2" ref="bulkFile" />
                            </div>
                            <button
                                type="submit"
                                class="-ml-px relative inline-flex items-center space-x-2 px-8 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-indigo-600 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1">
                                <span>UPLOAD</span>
                            </button>
                        </div>
                    </form>
                    <div v-if="showExcelErrorButton" class="h-14 flex flex-col justify-center">
                        <button class="m-0 p-0" @click="clickExcelErrorButton"><p class="text-red-600 text-left">Invalid file format. Please check your file and try again.</p></button>
                    </div>

                    <div v-if="showNoFileErrorString" class="h-14 flex flex-col justify-center">
                        <p class="text-red-600">Please choose a file to upload.</p>
                    </div>
                </div>
                <!-- slot -->
            </div>
            <div v-if="buttonText" class="w-full h-px bg-gray-200"></div>
            <div class="px-4 py-6 flex justify-end" v-if="buttonText">
                <ElementsButton text="Cancel" :width28="true" :height12="true" :background-white="true" @click-event="hidePopup" />
                <ElementsButton class="ml-2" :backgroundWhite="ableAddButton" :disabled="ableAddButton" :text="buttonText" :width28="true" :height12="true" @click-event="clickAddButton" />
            </div>
        </div>
    </div>
    <div v-show="showCheckInviteBulkPopup" class="z-[80] fixed top-0 w-full h-full bg-black/50 flex justify-center items-center overflow-auto">
        <div class="border rounded-lg shadow shadow-gray-100 bg-white">
            <div class="px-7 py-6 flex justify-between items-center">
                <h2 class="text-xl font-bold">{{ checkInviteName }}</h2>
                <a href="javascript:void(0)" class="ml-20" @click="checkHidePopup">
                    <img src="@/assets/img/app-popup-1.svg" alt="app-popup-1" />
                </a>
            </div>
            <div class="w-full h-px bg-gray-300"></div>
            <div class="max-h-[70vh] overflow-scroll">
                <table class="min-w-full divide-y divide-gray-300 mx-auto">
                    <thead class="bg-gray-50">
                        <tr>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">First Name</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Last Name</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Phone Number</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Invite Email</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Join Date</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Department</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Head</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tier</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Office</th>
                            <template v-if="!$appUtil.checkSubscription('FLEXBEN_ONLY_USAGE')">
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Work Schedule</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">PaymentType</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">BasicSalaryAmount</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">TaxCode</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">TIN No.</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">SSSNo</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">PagIbigNo</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">PhilHealthNo</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">RDOCode</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">BankName</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">AccountNumber</th>
                            </template>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                        <tr v-for="(employee, index) in getInviteList" v-bind:key="index">
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.firstName }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.lastName }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.phoneNumber }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.inviteEmail }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ convertDateTimeToDate(employee.joinDate) }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.department }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.head }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.tier }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.office }}</td>
                            <template v-if="!$appUtil.checkSubscription('FLEXBEN_ONLY_USAGE')">
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.workSchedule }}</td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.paymentType }}</td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.basicSalaryAmount }}</td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.taxCode }}</td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.tinNo }}</td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.sssNo }}</td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.pagIbigNo }}</td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.philHealthNo }}</td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.rdoCode }}</td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.bankName }}</td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ employee.accountNumber }}</td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="checkButtonText" class="w-full h-px bg-gray-200"></div>
            <div class="px-4 py-6 flex justify-end" v-if="checkButtonText">
                <ElementsButton text="Cancel" :width28="true" :height12="true" :background-white="true" @click-event="checkHidePopup" />
                <ElementsButton class="ml-2" :text="checkButtonText" :width28="true" :height12="true" @click-event="clickInviteBulkButton" />
            </div>
        </div>
    </div>
</template>
